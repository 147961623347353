import styled from 'styled-components'

export const Wrapper = styled.div`
display:flex;
flex-direction:column;
flex-basis:100%;
padding:20px 0px;
/* text-align:center; */
h4{
    margin:0;
    color:#353535;
}
h1{
    margin:0;
    text-decoration:underline;
    text-decoration-color:#009900;
}
`