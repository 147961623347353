import React, { Fragment } from 'react'
import THS from '../../components/Copyright/TumHaklariSaklidir';
import Whatsapp from '../../components/Whatsapp/';
import ContactUs from '../../components/Footer';
export default (props) => {
    return (props.routes && <Fragment>
            <ContactUs apiUrl={props.apiUrl}/>
            <THS apiUrl={props.apiUrl}/>
            <Whatsapp apiUrl={props.apiUrl}/>
        </Fragment>)

}