import styled from 'styled-components';

export const ContactUsSC = styled.div`
position:relative;
background-color:#3a3737;
padding:0px;
display:flex;
flex-direction:row;
align-content:space-between;
z-index:1000;
@media screen and (max-width: 1000px) {
    flex-direction:column;
  }
`
export const Left = styled.div`
flex-basis:50%;
padding:50px 120px;
box-sizing:border-box;
@media screen and (max-width: 1000px) {
    padding:50px 50px;
  }
`
export const Right = styled.div`
flex-basis:50%;
display:flex;
flex-direction:row;
justify-content:stretch;
align-content:stretch;
align-items:stretch;
iframe{
    flex-basis:100%;
}
`
export const Baslik = styled.p`
font-family:'Playfair Display';
color:white;
font-size:20px;
`
export const List = styled.ul`
color:#849f93;
font-size:14px;
margin:0px;
padding:0px;
list-style:none;
letter-spacing:1px;
font-weight:400;
`