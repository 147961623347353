import React, { useState, useEffect, useContext } from 'react'
import TranslateStatic from '../../locales/TranslateStatic';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LocaleContext } from "../../store/url";
import Fade from 'react-reveal/Fade';
import { HotelSC, Baslik, Wrapper, ButtonSC, Splitter,Splitter2 } from './style'
import {toast} from 'react-toastify'
export default function ProductList(props) {
   
    const locale = useContext(LocaleContext);
    const [talep, setTalep] = useState({
        name: false,
        email: false,
        note: false,
    })
    function _onChange(e) {
        console.log(e.target.name)
        console.log(e.target.value)
        setTalep({ ...talep, [e.target.name]: e.target.value })
    }
    function _talepGonder(e) {
        e.preventDefault();
        console.log(talep)
        fetch(props.apiUrl + 'mailat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    name: talep.name,
                    email: talep.email,
                    note: talep.note,
                }
            }
            )
        })
            .then(response => response.json()).then((data)=>{
                if(data.error){
                    toast.error('Mesaj gönderilemedi');
                }else{
                    toast.success('Mesaj iletildi');
                }
            })
    }
    return (<Fade bottom>
        <HotelSC name="contact">
            <Baslik><TranslateStatic string="mesaj_gonder"/></Baslik>
            <Wrapper>
            <Splitter>
                <label>
                    <span><TranslateStatic string="isim" /></span>
                    <input type="textbox" name="name" onChange={(e)=>_onChange(e)}/>
                </label>
            </Splitter>
            <Splitter>
                <label>
                    <span>Email</span>
                    <input type="textbox" name="email" onChange={(e)=>_onChange(e)}/>
                </label>
            </Splitter>
        </Wrapper>
        <Wrapper>
        <Splitter2>
                <label>
                    <span><TranslateStatic string="note" /></span>
                    <textarea name="note" rows="6" onChange={(e)=>_onChange(e)}/>
                </label>
            </Splitter2>
        </Wrapper>
        <Wrapper>
            <ButtonSC onClick={(e)=>_talepGonder(e)}><TranslateStatic string="gonder" /></ButtonSC>
        </Wrapper>
        </HotelSC>
    </Fade>
    )
}