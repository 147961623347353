import React from 'react';
import { IcerikSC } from './styles'
import {Link} from 'react-router-dom'
import { FaGolfBall } from "react-icons/fa";
import TranslateStatic from './translate'
export default (props) => {
    return <IcerikSC>
        {/*TODO: whatsinclude diğer dil karşılıkları*/}
        <h3><TranslateStatic string="golf_rounds_at" /></h3>
        <ul>
            {props.data.map((x, i) => {
                return <li><FaGolfBall /> <Link to={{pathname:`/sahadetail/${x.id}`}}>{x.name}</Link></li>
            })}
        </ul>

    </IcerikSC>
}