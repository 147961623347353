import React, { useState } from 'react';
import { AiOutlineMenu } from "react-icons/ai";

import styled from 'styled-components'
import Logo from '../../components/Header/Logo'
import Nav from '../../components/Header/Nav'
import LangChanger from '../Header/LangChanger'
const Header = styled.header`
width:100%;
display:flex;
flex-direction:row;
justify-content:space-between;
align-content:stretch;
position:fixed;
z-index:5000;
background:white;
@media screen and (max-width: 1100px) {
    padding: 10px 0px;
  }
`
const FullMenu = styled.div`
position:fixed;
width:100%;
height:100vh;
background:white;
z-index:2000;
`
const MenuIcon = styled.div`
display:flex;
flex-direction:column;
align-content:center;
align-items:center;
justify-content:center;
color:#cca643;
svg{
  width:80px;
  height:30px;
}
`
const RigthSide = styled.div`
display:flex;
flex-direction:row;
`
export default function MiniHeader(props) {
  const [show, setShow] = useState(false);
  return (<Header>
    <Logo />
    <RigthSide>
     <LangChanger lang={props.lang} changeLanguage={props.changeLanguage} /> 
     <MenuIcon onClick={() => setShow(!show)}><AiOutlineMenu/></MenuIcon>
    </RigthSide>
    
    {show ? <FullMenu>
      
      <Nav routes={props.routes} apiUrl={props.apiUrl} click={setShow}>
        
      </Nav>
    </FullMenu> : false}


    {/* <Nav routes={props.routes} apiUrl={props.apiUrl}>
      <LanguagePicker changeLanguage={props.changeLanguage} />
    </Nav> */}
    {/* {props.sticky ? <SubNav routes={props.routes} /> : false} */}

  </Header>)

}