import styled from 'styled-components'
import SubScribe from '../../assets/subscribebg.jpg'
export const SubscribeSC = styled.div`
padding:120px 120px;
display:flex;
height:calc(100vh - 90px);
box-sizing:border-box;
background-image:url(${SubScribe});
background-position: center !important;
background-repeat: no-repeat !important;
background-size: cover !important;
flex-direction:column;
@media screen and (max-width:1000px){
    padding:50px;
}
`
export const Baslik = styled.h1`
width:100%;
color:white;
font-family:'Playfair Display';
font-style:italic;
font-weight:700;
font-size:39px;
line-height:52px;
float:left;
margin-bottom:0px;
`
export const AltBaslik = styled.h5`
width:100%;
float:left;
color:white;
/* font-family:'Playfair Display'; */
font-weight:400;
font-size:12px;
/* line-height:52px; */
`
export const ButtonSC = styled.button`
margin:0px auto;
border:none;
border-radius:3px;
font-family:'Oswald';
text-transform:uppercase;
font-size:16px;
font-weight:600;
background-color:#cca643;
color:white;
padding:10px 30px;
transition:300ms;
&:hover{
   transition:300ms;
   background-color:#85a093;
}&:focus{
    border:none;
    outline:none;
}
`
