import React from 'react';
import { Link } from 'react-router-dom';
import Translate from '../locales/Translate';

export default class SubNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAboutMenu: props.routes.map((x, i) => false)
    };
    this.handleHover = this.handleHover.bind(this);
    this.handleLeave = this.handleLeave.bind(this);

  }

  handleHover = (i) => {
    console.log(`Bastım:${i}`)
    this.setState(state => {
      const showAboutMenu = state.showAboutMenu.map((item, j) => {
        if (j === i) {
          console.log(j)
          console.log(i)
          return true;
        } else {
          return item;
        }
      });
      return {
        showAboutMenu,
      };
    });
  };

  handleLeave = (i) => {

    this.setState(state => {
      const showAboutMenu = state.showAboutMenu.map((item, j) => {
        if (j === i) {
          return false;
        } else {
          return item;
        }
      });
      return {
        showAboutMenu,
      };
    });
  };

  render() {
    let { handleLeave, handleHover } = this;
    let { showAboutMenu } = this.state;
    function BookmarkNode({ node, i }) {
      console.log(i)
      if (node.MainSub) {
        return <li key={i} onMouseLeave={() => handleLeave(i)} ><Link to={node.path} onMouseEnter={() => handleHover(i)}><Translate deger={node} string="name" /></Link>
          {showAboutMenu[i] && node.subMenuArr ?

              <ul onMouseLeave={() => handleLeave(i)}>


              {node.subMenuArr.map((c, i) => (c.subMenuArr?<BookmarkNode node={c} i={i} />:<li key={c.id}><Link to={c.path}><Translate deger={c} string="name" /></Link></li>))}
              </ul> : false}</li>;
      } else {
        return false
      }
      // if (node.MainSub) {
      //   return <li key={i} onMouseLeave={() => handleLeave(i)} ><Link to={node.path} onMouseEnter={() => handleHover(i)}><Translate deger={node} string="name" /></Link>
      //     {showAboutMenu[i] && node.subMenuArr ?

            


      //         node.subMenuArr.map((c, i) => (c.subMenuArr?<ul onMouseLeave={() => handleLeave(i)}><li key={c.id}> <BookmarkNode node={c} i={i} /> </li></ul>:<li key={c.id}><Link to={c.path}><Translate deger={c} string="name" /></Link></li>))
      //        : false}</li>;
      // } else {
      //   return false
      // }
      console.log(showAboutMenu)
      // return (
      //   <div>
      //     {showAboutMenu[node.id] ?
      //       <ul onMouseLeave={() => handleLeave(node.id)}>

      //         {node.subMenuArr.map((c,i) => (<li key={c.id}> <BookmarkNode node={c} i={i}/> </li>))}
      //       </ul> : false}
      //   </div>
      // );
      console.log(i)

    };

    console.log(this.props.routes)
    return (
      <div className="subnav">
        <ul>

          {this.props.routes.map(function (x, i) { return <BookmarkNode key={i} node={x} i={i} /> })}



        </ul>
      </div>
    )

  }

}