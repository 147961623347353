import ReactGA from "react-ga"
import axios from 'axios';
import { LocaleContext } from '../store/url'
import authHeader from '../utils/authHeader'


const apiUrl = LocaleContext._currentValue;
async function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  const google = await axios.get(apiUrl + '/analytics/name/Google', { headers: authHeader() })
    .then(list => {
      // console.log(list)
      // ReactGA.initialize(list.data.data[0].trackedvalue)
      return list.data.data[0].trackedvalue
    })

  ReactGA.initialize(google, { debug: isDev })
  console.log(google)
}

function sendEvent(payload) {
  ReactGA.event(payload)
}

async function sendPageview(path) {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}

export default {
  init,
  sendEvent,
  sendPageview,
}