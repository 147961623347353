import React from 'react';
import {Par, Content,LeftImg,RightImg} from './style'
import SagYaprak from '../../assets/a2 .png'
import SolYaprak from '../../assets/a1.png'

export default function Spanner(props) {
  
  return <Par>
    <Content>
      <LeftImg>
        <img src={SolYaprak} alt="sol"/>
      </LeftImg>
      {props.children}
      <RightImg>
        <img src={SagYaprak} alt="sag"/>
      </RightImg>
    </Content>
  </Par>

}