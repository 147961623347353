import React from 'react';
import {Wrapper} from './styles'
const index = ({search}) => {
    return (
        <Wrapper>
            <h4>BEST RESULTS</h4>
            <h1>{search}</h1>
        </Wrapper>
    );
};

export default index;