import firebase from 'firebase/app'
import 'firebase/messaging';
const config ={
    apiKey: "AIzaSyCMwP-AAGPuqXYjo_Z53gIjrtC3fWgIeoI",
    authDomain: "cariagolf.firebaseapp.com",
    projectId: "cariagolf",
    storageBucket: "cariagolf.appspot.com",
    messagingSenderId: "796334371154",
    appId: "1:796334371154:web:d316b087948e0f8a6b0a8b"
}
firebase.initializeApp(config)

export default firebase