import React from 'react';
import TranslateStatic from './translate';
import styled from 'styled-components'

const THSC = styled.div`
background-color:#2e2c2c;
padding:20px 120px;
display:flex;
flex-direction:column;
align-content:space-between;
color:white;
font-size:12px;
z-index:1000;
`

export default function THS() {
    return (
        <THSC>
            {/* <p>
                <TranslateStatic string={'THS.Paragragh'} />
            </p>
            <hr></hr> */}
            <p>
                <TranslateStatic string={'THS.Copyright'} />
            </p>
        </THSC>
    );
}