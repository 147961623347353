import React from 'react';
import LogoSC from './style';
import LogoImg from '../../../assets/golf-logo.jpg';
import TranslateStatic from './translate'
export default function Logo() {
    return <LogoSC>
        <img src={LogoImg} alt="cariagolf logo" />
        <div>
            <h5><TranslateStatic string={"slogan"}/></h5>
        </div>
    </LogoSC>
}