import React, { useEffect, useState, useContext, Fragment } from 'react';
import styled from 'styled-components'
import { useParams, Link } from "react-router-dom";
import { PageDetailSC, LeftSC, RightSC, PaketBaslik, Wrapper, PaketImg } from './style'
import Gallery from './itemgallery'

import { EditorState, convertToRaw, convertFromHTML, CompositeDecorator, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LocaleContext } from "../../store/url";
import Pakets from './pakets'
import Slide from 'react-reveal/Slide';
import TranslateStatic from '../../locales/TranslateStatic'
import mapConverter from '../../utils/mapConverter'
import { _sahaDetay } from './services'
import Scorecard from './scorecard'
export default function PageDetail(props) {
    let { id } = useParams();
    const [data, setState] = useState(false);
    const locale = useContext(LocaleContext);
    useEffect(() => {
        window.scrollTo(0, 0)
        _sahaDetay(id)
            .then(list => {
                setState(list.data.data)
            })
    }, [props.apiUrl, id]);
    function _editorStateChanger(data, alan) {
        const veri = data[`${alan}${locale}`];
        const blocksFromHtml = htmlToDraft(veri);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        console.log(editorState)
        return editorState;
    }
    return <Fragment>
        <PaketImg>
            <PaketBaslik>{data ? data.name : false}</PaketBaslik>
        </PaketImg>
        <Slide bottom>

            <PageDetailSC>

                <Wrapper>
                    <LeftSC>
                        {data ? <h4>{data.name}</h4> : false}
                        {data ? <Gallery
                            apiUrl={props.apiUrl}
                            gallery_id={data.gallery[0] ? data.gallery[0].id : false}
                            hotel_id={data.id} /> : false}
                        {data ? <div>
                            <Editor
                                toolbarHidden
                                editorState={_editorStateChanger(data.aciklama[0], '')}
                                readOnly
                            />
                        </div> : false}
                        {data.map ? <iframe
                            title="saha map"
                            src={data.map != 'undefined' ? mapConverter(data.map) : false}
                            allowFullScreen=""
                            aria-hidden="false"
                            frameBorder="0"
                            tabindex="0"></iframe> : false}
                        <div label="Scorecards">
                            {data ? <Scorecard data={data.scorecard} /> : false}
                        </div>
                    </LeftSC>
                    <RightSC>
                        {/* <Link to={`/talep/${data.Paket_Id}`}><TranslateStatic string="rezervasyon" /></Link> */}
                        <h3><TranslateStatic string="sahaya_ait_hotele_ait_paketler" /></h3>
                        {data ? <Pakets apiUrl={props.apiUrl} hotel={data.hotel_id} /> : false}
                    </RightSC>
                </Wrapper>
            </PageDetailSC>
        </Slide>
    </Fragment>
}