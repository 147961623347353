import React, { useEffect, useState,useContext } from 'react';
import { PBaslikSC, Dater, PaketWrapper, HotelName,ButtonSC,AlinkSC,Detay } from './style';
import { EditorState, convertToRaw, convertFromHTML, CompositeDecorator, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LocaleContext } from "../../store/url";
import {Link} from 'react-router-dom'
import TranslateStatic from '../../locales/TranslateStatic'

export default function Pakets(props) {
    const [data, setState] = useState(false);
    const locale = useContext(LocaleContext);
    function _allPaket() {
        console.log(props.hotel)
        fetch(props.apiUrl + `all_hotel_pakets/${props.hotel}`).then(res => {
            let routelist = res.json();
            console.log(routelist)
            return routelist;
        })
            .then(list => {
                console.log(list)
                setState(list.data)
            })
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        _allPaket()
    }, []);
    function _editorStateChanger(data, alan) {
        const veri = data[`${alan}${locale}`];
        const blocksFromHtml = htmlToDraft(veri);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        console.log(editorState)
        return editorState;
    }
    return <div>
        {data ? data.map((x, i) => {
            if(i<4){
                return <PaketWrapper>
                <PBaslikSC>{x.single_price} - {x.currency}</PBaslikSC>
                <HotelName>{x.Hotel_Name}</HotelName>
                <Dater>
                    {x.start_date.substring(0,10)} | {x.end_date.substring(0,10)}
                </Dater>
                {x ? <Detay>
                            <Editor
                                toolbarHidden
                                editorState={_editorStateChanger(x, '')}
                                readOnly
                            />
                        </Detay> : false}
                        {/* <Link to={`/talep/${x.Paket_Id}`}><TranslateStatic string="talep" /></Link> */}
                        <Link to={`/paket/${x.id}`}><TranslateStatic string="detay" /></Link>
            </PaketWrapper>
            }else{
                return false
            }
            
        }) : false}
    </div>
}