import React, { useState } from 'react'
import Header from '../../components/Header/';
import MiniHeader from '../../components/MiniHeader/';
import useWindowSize from '../../utils/use-window-size';
import { LocaleContext } from '../../store/url'
export default function (props) {
    const [visible] = useState(true);
    const size = useWindowSize();
    const apiUrl = LocaleContext._currentValue;
    return props.routes ? size.width < 1100 ?
        <MiniHeader
            routes={props.routes}
            sticky={visible}
            changeLanguage={props.changeLanguage}
            apiUrl={apiUrl} />
        :
        <Header
            lang={props.preferredLocale}
            routes={props.routes}
            sticky={visible}
            changeLanguage={props.changeLanguage}
            apiUrl={apiUrl} /> : false
}