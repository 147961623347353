import React, { useState } from 'react';
import { TeeTimeSC, Baslik, AllWrap, Wrapper, Splitter, ButtonSC } from './style'
import Translate from '../../locales/TranslateStatic';
import { toast } from 'react-toastify';
import { _talepKaydet } from './services'
export default function Tee(props) {
    const [talep, setTalep] = useState({
        name: false,
        phone: false,
        email: false,
        arrivaldate: false,
        golfer: false,
    })
    function _onChange(e) {
        console.log(e.target.name)
        console.log(e.target.value)
        setTalep({ ...talep, [e.target.name]: e.target.value })
    }
    function _talepGonder(e) {
        e.preventDefault();
        console.log(talep)
        let data = {
            talep: {
                ...talep,
                // departuredate: new Date(talep.departuredate).toLocaleDateString("en-GB", {
                //     year: "numeric",
                //     month: "2-digit",
                //     day: "2-digit",
                // }),
                // arrivaldate: new Date(talep.arrivaldate).toLocaleDateString("en-GB", {
                //     year: "numeric",
                //     month: "2-digit",
                //     day: "2-digit",
                // })

            },
            mailAt: true
        }
        console.log(data)

        // fetch(props.apiUrl + 'talep', {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         data: {
        //             ...data
        //         }
        //     }
        //     )
        // })
        //     .then(response => response.json())
        _talepKaydet({
            data: {
                ...data,
            }
        })
            .then((data) => {
                if (data.data.error) {
                    toast.error('Mesaj gönderilemedi');
                } else {

                    toast.success('Talep iletildi');
                  
                }
            })
        // fetch(props.apiUrl + '/teetimesend', {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         data: {
        //             name: talep.name,
        //             phone: talep.phone,
        //             email: talep.email,
        //             date: talep.date,
        //             player: talep.player,
        //         }
        //     }
        //     )
        // })
        // .then(response => response.json()).then((data)=>{
        //     if(data.error){
        //         toast.error('Mesaj gönderilemedi');
        //     }else{
        //         toast.success('Talep iletildi');
        //     }
        // })
    }
    return <TeeTimeSC name="tee">
        <Baslik><Translate string="teetime_baslik" /></Baslik>
        <AllWrap>
            <Wrapper>
                <Splitter>
                    <label>
                        <span>Name</span>
                        <input type="textbox" name="name" onChange={(e) => _onChange(e)} />
                    </label>
                </Splitter>
                <Splitter>
                    <label>
                        <span>Contact Phone</span>
                        <input type="textbox" name="phone" onChange={(e) => _onChange(e)} />
                    </label>
                </Splitter>
            </Wrapper>
        </AllWrap>
        <AllWrap>
            <Wrapper>
                <Splitter>
                    <label>
                        <span>Date</span>
                        <input type="datetime-local" name="arrivaldate" onChange={(e) => _onChange(e)} />
                    </label>
                    <label>
                        <span>Player</span>
                        <select name="golfer" onChange={(e) => _onChange(e)}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </label>
                </Splitter>
                <Splitter>
                    <label>
                        <span>Email</span>
                        <input type="textbox" name="email" onChange={(e) => _onChange(e)} />
                    </label>
                </Splitter>

            </Wrapper>
        </AllWrap>
        <AllWrap>
            <Wrapper>
                <ButtonSC onClick={(e) => _talepGonder(e)}>Submit</ButtonSC>
            </Wrapper>
        </AllWrap>
    </TeeTimeSC>
}