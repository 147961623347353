import React from 'react';
import { Link } from 'react-router-dom';
import Translate from '../locales/Translate';

export default class DropdownMenu extends React.Component {
  
  getMenuItemTitle = (menuItem, index, depthLevel) => {
    return menuItem.name;
  };

  getMenuItem = (menuItem, index,depthLevel) => {
    let title = this.getMenuItemTitle(menuItem, index, depthLevel);

    if (menuItem.subMenuArr && menuItem.subMenuArr.length > 0) {
      return (
        <li>
          {/* {title+" "+depthLevel} */}
          <Link to={menuItem.path}><Translate deger={menuItem} string="name" /></Link>
          <DropdownMenu config={menuItem.subMenuArr} depthLevel={depthLevel+1} subMenuArr={true} />
        </li>
      );
    } else {
      return menuItem.MainSub?<li key={title}><Link to={menuItem.path}><Translate deger={menuItem} string="name" /></Link></li>:false;
    }
  };


  render = () => {
    let { config,depthLevel } = this.props;

    let options = [];
    config.map((item, index) => {
      return options.push(this.getMenuItem(item, index,depthLevel ));
    });

    if (this.props.subMenuArr && this.props.subMenuArr === true)
      return <ul>{options}</ul>;

    return <ul className="dropdown-menu">{options}</ul>;
  };


}