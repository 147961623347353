import React from 'react'
import {Link} from 'react-router-dom'
import { UL,H4 } from './styles'
export default (props) => {
    return props.data ? <>
        <H4>{props.baslik}</H4>
        <UL>
            {props.data.map((x, i) => {
                return <li>
                    <Link 
                    to={{ pathname: '/'+props.link+'/'+x.id }}
                    >{x.name}</Link>
                    </li>
            })}
        </UL></> : false
}