import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Swiper from 'react-id-swiper';
// import 'swiper/swiper.scss'
import 'swiper/css/swiper.css';
import LazyLoad from "react-lazyload";
import Imager from './imager'
import {_gallerylistesi} from './services'
import { LocaleContext } from '../../store/url'
const API_URL = LocaleContext._currentValue;
const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 1s infinite;
`;
const GallerySC = styled.div`
width:100%;
height:300px;
cursor:pointer;
img{
 width:100%;
 height:300px;
 object-fit:cover;
}
`;
const CustomButton = styled.div`
color:#85a093;
opacity:0.8;
cursor: pointer;
&:hover{
    opacity:1;
    transition:300ms;
}
`
const StyledImage = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const params = {
    loop: false,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    renderPrevButton: () => <CustomButton className="swiper-button-prev"></CustomButton>,
    renderNextButton: () => <CustomButton className="swiper-button-next"></CustomButton>,
}
export default function ItemGallery(props) {
    const [state, setState] = useState(false);
    useEffect(() => {
        _gallerylistesi(props.gallery_id)
            .then(list => {
                setState(list.data.data)
            })
    }, [props.gallery_id])
    function _allPaket() {
        fetch(props.apiUrl + `all_hotels_image/${props.hotel_id}`).then(res => {
            let routelist = res.json();

            return routelist;
        })
            .then(list => {

                setState(list.data)
            })
    }
    const refPlaceholder = useRef([]);
    // refPlaceholder.current = [];
    const addToRefs = el => {
        console.log(el)
        if (el && !refPlaceholder.current.includes(el)) {
            refPlaceholder.current.push(el);
        }
       };
    const removePlaceholder = (i) => {
        console.log(i)
        console.log(refPlaceholder.current.length)
        if(refPlaceholder.current.length > 0){
            console.log(`girdi${i}`)
            refPlaceholder.current[i].remove();
        }
        
    };
   

    
   
    return <GallerySC>{state ? <Swiper {...params}>
    {state
    .sort((c, d) => c.sira_no - d.sira_no)
    .sort((a, b) => (a.set_cover === b.set_cover) ? 0 : a ? -1 : 1)
    .map((x, i) => {
        return <div key={i}><Imager name={x.name} apiUrl={API_URL}/></div>

    })}
</Swiper> : false}</GallerySC>
}