import styled from 'styled-components'
export const H4 = styled.h4`
margin:0px;
`;
export const UL = styled.ul`
flex-basis:100%;
padding:0px;
margin:0px;
li{
    list-style:none;
    a{
        text-decoration:none;
        color:black;
        &:hover{
            border-bottom:4px solid #009900; 
            /* text-decoration: underline solid #009900; */
            /* text-decoration-style: ; */
            
        }
    }
}
`