export default function authHeader() {
    const user = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtdXVpZCI6MiwibWVtYWlsIjoiZXJlbmVyb3phbkBnbWFpbC5jb20iLCJtZXVzZXJuYW1lIjoiZXJlbmVyb3phbiJ9.5mFtRNkAgpNRicAmn5ashdcoXuzWTRTVaXbme6JJXMg'
    if (user) {
        // for Node.js Express back-end
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Source':'frontend',
            Authorization: 'Bearer ' + user
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Source':'frontend'
        };
    }
}