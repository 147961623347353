import React, { useEffect } from 'react'
import { LocaleContext } from './store/url'
import axios from "axios";
import authHeader from './utils/authHeader'
import firebase from './firebase'

export default function () {
    const API_URL = LocaleContext._currentValue;
    
    useEffect(() => {
        async function getToken2() {
            let body = {}
            console.log("data")
            const messaging = await firebase.messaging()
            messaging.getToken({ vapidKey: process.env.PUBLIC_VAPID_KEY }).then(async(token) => {
                console.log('Token : ', token)
               await axios.post(API_URL + `/subscription`, { body, token }, { headers: authHeader() })
                    .then(function (data) {
                        console.log(data)
                    });
            }).catch((err) => {
                console.log(err);
    
            })
        }
        const cevap = getToken2()
    }, [API_URL])
    return false;
}