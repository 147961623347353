import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Translate from '../../../locales/Translate';
import TranslateStatic from '../../../locales/TranslateStatic';
import NavSC from './style'
import SmoothScrool from '../scroller'
import { useLocation } from 'react-router-dom';
export default function Nav(props) {
  const location = useLocation().pathname;
  console.log(location)
  return (
    <NavSC onClick={()=>props.click(false)}>
      {location !== '/' ? <React.Fragment>
        {props.children}
        {props.routes.map((x, i) => x.MainSub ? false : <li key={i}><Link to={{pathname:"/",query:"slider"}}><TranslateStatic string="home" /></Link></li>)}
        <li><Link to={{pathname:"/",query:"golfpaketleri"}}><TranslateStatic string="golf_packets" /></Link></li>
        <li><Link to={{pathname:"/",query:"hotel"}}><TranslateStatic string="hotels" /></Link></li>
        <li><Link to={{pathname:"/",query:"saha"}}><TranslateStatic string="courses" /></Link></li>
        <li><Link to={{pathname:"/",query:"contact"}}><TranslateStatic string="contact" /></Link></li>
      </React.Fragment> : <React.Fragment>{props.children}
          {props.routes.map((x, i) => x.MainSub ? false : <li key={i}><span onClick={() => SmoothScrool.scrollTo('slider', 90)}><TranslateStatic string="home" /></span></li>)}
          <li><span onClick={() => SmoothScrool.scrollTo('golfpaketleri', 40)}><TranslateStatic string="golf_packets" /></span></li>
          <li><span onClick={() => SmoothScrool.scrollTo('hotel', 40)}><TranslateStatic string="hotels" /></span></li>
          <li><span onClick={() => SmoothScrool.scrollTo('saha', 40)}><TranslateStatic string="courses" /></span></li>
          <li><span onClick={() => SmoothScrool.scrollTo('contact', 40)}><TranslateStatic string="contact" /></span></li>
        </React.Fragment>
      }
    </NavSC>
  )

}