import React, { useEffect, useState, useContext, Fragment } from 'react';
import styled from 'styled-components'
import { useParams } from "react-router-dom";
import { PageDetailSC, FullSC, RightSC, PaketBaslik, Wrapper, PaketImg, ButtonSC } from './style'
import Gallery from './itemgallery'

import { EditorState, convertToRaw, convertFromHTML, CompositeDecorator, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LocaleContext } from "../../store/url";
import Pakets from './pakets'
import Slide from 'react-reveal/Slide';
import TranslateStatic from '../../locales/TranslateStatic'
import { toast } from 'react-toastify';
import { _paketDetay, _talepKaydet } from './services'
export default function Talep(props) {
    let { id } = useParams();
    const [data, setState] = useState(false);
    const locale = useContext(LocaleContext);
    const [talep, setTalep] = useState({
        name: false,
        last_name: false,
        phone: false,
        email: false,
        note: false,
    })
    console.log(locale)
    useEffect(() => {
        window.scrollTo(0, 0)
        _paketDetay(id).then((res) => {
            setState(res.data.data)
        })
    }, [id]);
    function _onChange(e) {
        console.log(e.target.name)
        console.log(e.target.value)
        setTalep({ ...talep, [e.target.name]: e.target.value })
    }
    // function _talepGonder(e) {
    //     e.preventDefault();
    //     console.log(talep)
    //     fetch(props.apiUrl + 'talepsend', {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             data: {
    //                 name: talep.name,
    //                 last_name: talep.last_name,
    //                 phone: talep.phone,
    //                 email: talep.email,
    //                 note: talep.note,
    //                 packet: data.name
    //             }
    //         }
    //         )
    //     }).then(response => response.json()).then((data) => {
    //         if (data.error) {
    //             toast.error('Mesaj gönderilemedi');
    //         } else {
    //             toast.success('Talep iletildi');
    //         }
    //     })
    // }
    function _talepGonder(e) {
        e.preventDefault();
        let senddata = {
            talep: {
                ...talep,
                packet_id: data.id,
                // departuredate: new Date(talep.departuredate).toLocaleDateString("en-GB", {
                //     year: "numeric",
                //     month: "2-digit",
                //     day: "2-digit",
                // }),
                // arrivaldate: new Date(talep.arrivaldate).toLocaleDateString("en-GB", {
                //     year: "numeric",
                //     month: "2-digit",
                //     day: "2-digit",
                // })

            },
            mailAt: true,
            packet: data.id
        }
        // fetch(props.apiUrl + 'talep', {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         data: {
        //             ...senddata,
        //         }
        //     }
        //     )
        // })
        _talepKaydet({
            data: {
                ...senddata,
            }
        })
            .then((data) => {
                if (data.data.error) {
                    toast.error('Mesaj gönderilemedi');
                } else {
                    toast.success('Talep iletildi');
                }
            })
    }
    function _editorStateChanger(data, alan) {
        const veri = data[`${alan}${locale}`];
        const blocksFromHtml = htmlToDraft(veri);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        console.log(editorState)
        return editorState;
    }
    return <Fragment>
        <PaketImg>
            <PaketBaslik>{data.name}</PaketBaslik>
        </PaketImg>
        <Slide bottom>
            <PageDetailSC>
                <Wrapper>
                    <FullSC>
                        {/* <h4>{data.Hotel_Name}</h4> */}
                        <h4><TranslateStatic string="online_talep" /></h4>
                        {/* {data ? <Gallery apiUrl={props.apiUrl} hotel_id={data.Hotel_Id} /> : false} */}

                        {data ? <form>
                            <label>
                                <span><TranslateStatic string="isim" /></span>
                                <input name="name" type="textbox" onChange={(e) => _onChange(e)} />
                            </label>
                            <label>
                                <span><TranslateStatic string="soyisim" /></span>
                                <input name="last_name" type="textbox" onChange={(e) => _onChange(e)} />
                            </label>
                            <label>
                                <span><TranslateStatic string="telefon" /></span>
                                <input name="phone" type="textbox" onChange={(e) => _onChange(e)} />
                            </label>
                            <label>
                                <span>Email</span>
                                <input name="email" type="textbox" onChange={(e) => _onChange(e)} />
                            </label>
                            <div>
                                <label>
                                    <span><TranslateStatic string="note" /></span>
                                    <textarea name="note" rows="6" onChange={(e) => _onChange(e)} />
                                </label>
                            </div>
                            <article>
                                <label>
                                    <span><TranslateStatic string="paket_icerigi" /></span>
                                    {/* <input type="textbox" /> */}
                                    <Editor
                                        toolbarHidden
                                        editorState={_editorStateChanger(data.aciklama[0], '')}
                                        readOnly
                                    />
                                </label>
                            </article>
                            <ButtonSC onClick={(e) => _talepGonder(e)}><TranslateStatic string="gonder" /></ButtonSC>
                        </form> : false}
                    </FullSC>
                </Wrapper>
            </PageDetailSC>
        </Slide>
    </Fragment>
}