import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';


import 'swiper/css/swiper.css';

const GallerySC = styled.div`
width:100%;
height:auto;
transition:300ms;
float:left;
position:relative;
cursor:pointer;
img{
 width:100%;
 height:350px;
 object-fit:cover;
 border-radius:3px;
}
`;
const CustomButton = styled.div`
color:#85a093;
opacity:0.8;
cursor: pointer;
&:hover{
    opacity:1;
    transition:300ms;
}
`
const params = {
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    renderPrevButton: () => <CustomButton className="swiper-button-prev"></CustomButton>,
    renderNextButton: () => <CustomButton className="swiper-button-next"></CustomButton>,
}

export default function ItemGallery(props) {
    const [gallerySwiper, getGallerySwiper] = useState(null);
    const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
    const gallerySwiperParams = {
        getSwiper: getGallerySwiper,
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    };
    const thumbnailSwiperParams = {
        getSwiper: getThumbnailSwiper,
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
    };
    console.log(props)
    const [state, setState] = useState(false);
    function _allPaket() {
        fetch(props.apiUrl + `all_hotels_image/${props.hotel_id}`).then(res => {
            let routelist = res.json();

            return routelist;
        })
            .then(list => {
                console.log(list)
                setState(list.data)
            })
    }
    useEffect(() => {
        _allPaket();
        if (
            gallerySwiper !== null &&
            gallerySwiper.controller &&
            thumbnailSwiper !== null &&
            thumbnailSwiper.controller
        ) {
            gallerySwiper.controller.control = thumbnailSwiper;
            thumbnailSwiper.controller.control = gallerySwiper;
        }
    }, [gallerySwiper, thumbnailSwiper])
    return <GallerySC>
        {state ? <Fragment><Swiper {...gallerySwiperParams}>
            {state.map((x, i) => {
                return <div key={i}>
                    <img src={x.path + '/' + x.name} alt="" />

                </div>
            })}
        </Swiper><Swiper {...thumbnailSwiperParams}>
                {state.map((x, i) => {
                    return <div style={{width:100,height:100}} key={i}>
                        <img style={{width:100,height:100}}src={x.path + '/' + x.name} alt="" />

                    </div>
                })}
            </Swiper> </Fragment> : false}</GallerySC>
}