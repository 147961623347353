import React from 'react';
import { Route, Switch } from "react-router-dom";
import Home from './home';
// import ContactPage from './contactpage';
import PageDetail from './PaketDetail';
import HotelDetail from './HotelDetail';
import SahaDetail from './SahaDetail';
import Talep from './Talep';
import CustomTalep from './CustomTalep'
import NoMatch from './noMatch/';
import Contact from './Contact'
import Saha from './Sahalar'
import Hotel from './Hoteller'
import Search from './Search'
export default (props) => {
    return <Switch>
        <Route exact path="/" >
            <Home apiUrl={props.apiUrl} />
        </Route>
        <Route path="/contact" >
            <Contact apiUrl={props.apiUrl} />
        </Route>
        <Route path="/paket/:id">
            <PageDetail apiUrl={props.apiUrl} />
        </Route>
        <Route path="/hoteldetail/:id">
            <HotelDetail apiUrl={props.apiUrl} />
        </Route>
        <Route path="/sahadetail/:id">
            <SahaDetail apiUrl={props.apiUrl} />
        </Route>
        <Route path="/talep/:id">
            <Talep apiUrl={props.apiUrl} />
        </Route>
        <Route path="/detailtalep">
            <CustomTalep apiUrl={props.apiUrl} />
        </Route>
        <Route path="/courses">
            <Saha apiUrl={props.apiUrl} />
        </Route>
        <Route path="/hotels">
            <Hotel apiUrl={props.apiUrl} />
        </Route>
        <Route path="/search/:id">
            <Search apiUrl={props.apiUrl} />
        </Route>
        <Route path="*">
            <NoMatch />
        </Route>
    </Switch>
}