import React from "react";
import { LocaleContext } from "../store/url";


export default function Translate(props) {
  const { string, deger } = props;
  return (
    <LocaleContext.Consumer>
      {value => deger[`${string}${value}`]}
    </LocaleContext.Consumer>
  );
}
// }