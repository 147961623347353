import React, { useState, useEffect, useContext } from 'react'
import Gallery from './itemgallery'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Fade from 'react-reveal/Fade';
import { HotelSC, Baslik, ItemWrapper, Item, ButtonSC, ButtonWrapperSC } from './style'
import TranslateStatic from '../../locales/TranslateStatic'
import { _sahalistesi } from './services'
export default function ProductList(props) {
    const [gosterimSayisi, setGs] = useState(3);
    const [state, setState] = useState(false);
    function listTopfunc(ascending) {

        return function (a, b) {

            // equal items sort equally
            if (a.list_sira_no === b.list_sira_no) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.list_sira_no === null) {
                return 1;
            }
            else if (b.list_sira_no === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (ascending) {
                return a.list_sira_no < b.list_sira_no ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a.list_sira_no < b.list_sira_no ? 1 : -1;
            }

        };

    }
    useEffect(() => {
        _sahalistesi()
            .then(list => {
                let sorter = list.data.data.sort(listTopfunc(true))
                setState(sorter)
            })
    }, [props.apiUrl])

    return (<Fade bottom>
        <HotelSC name="saha">
            <Baslik><TranslateStatic string="saha_baslik" /></Baslik>
            {state ? <ItemWrapper>{state.map((x, i) => {
                if (i < gosterimSayisi) {
                    return <Item key={i}>
                        <Gallery apiUrl={props.apiUrl}
                            hotel_id={x.id}
                            gallery_id={x.gallery[0] ? x.gallery[0].id : false}
                            name={x.name} map={x.map} />
                    </Item>
                } else {
                    return false
                }

            })}</ItemWrapper> : false}
            {state && state.length > 3 ? <ButtonSC onClick={() => setGs(gosterimSayisi + 3)}><TranslateStatic string="more" /></ButtonSC> : false}
        </HotelSC>
    </Fade>
    )
}