export default () => {
    let lang;
    switch (navigator.language) {
        case "tr-TR":
            lang = 'tr';
            break;
        case "tr":
            lang = 'tr';
            break;
        case "TR":
            lang = 'tr';
            break;
        case "de":
            lang = 'de';
            break;
        case "cs":
            lang = 'cz';
            break;
        case "cs-CZ":
            lang = 'cz';
            break;
        case "CZ":
            lang = 'cz';
            break;
        case "ru":
            lang = 'ru';
            break;
        case "en":
            lang = 'en';
            break;
        case "en-US":
            lang = 'en';
            break;
        case "US":
            lang = 'en';
            break;
        default:
            lang = 'en';
            break;
    }
    return lang;
}