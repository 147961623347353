import styled from 'styled-components';

export const Wrapper = styled.div`
flex-basis:100%;
display:flex;
flex-direction:row;
flex-wrap:wrap;
margin-bottom:1em;
font-family:'arial';
`
export const Baslik = styled.h2`
text-align:center;
flex-basis:100%;
`
export const TableHeader = styled.div`
flex-basis:100%;
display:flex;
flex-direction:row;
justify-content:stretch;
border:none;
border-radius:4px;
overflow:hidden;
div{
    flex:1;
    display:flex;
    flex-direction:column;
    text-align:center;
    &:last-child{
        flex-grow:2;
    }
    ul{
        margin:0;
        padding:0;
        li{
            padding:10px;
            list-style:none;
            &:nth-child(even){
                background-color:#dddddd;
            }
            &:nth-child(odd){
                background-color:#fff;
            }
        }
    }
}
`
export const TableHeaderSpan = styled.span`
background-color:${props=> props.renk};
color:${props=>props.textcolor};
padding:10px 0px;
font-weight:bold;
letter-spacing:0px;
font-size:14pt;
`