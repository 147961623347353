import React from 'react';
import { Wrapper, In,Bar,Logo } from './styles'
import Resim from '../../assets/nt1.svg'
import LogoImg from '../../assets/golf-logo.jpg'
export default () => {
    return <Wrapper>
        <Bar>
            <Logo>
                <img src={LogoImg} alt="logo"/>
            </Logo>
            <span>info@cariagolf.com</span>
        </Bar>
        <In>
            <p>Sorry, your browser is not supported.</p>
            <span>Please try it with modern web browsers.</span>
        </In>
        <In>
            <img src={Resim} alt="nt"/>
        </In>
    </Wrapper>
}