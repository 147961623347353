import React, { useState, useEffect, useRef } from 'react'
import { SliderSC, UstBaslik, AltBaslik, Altlink } from './style'
import styled, { keyframes } from 'styled-components'
import Swiper from 'react-id-swiper';
import { Parallax } from "react-parallax";
import Spanner from './span'
import 'swiper/swiper.scss'
import Translate from '../../locales/Translate'
import TranslateStatic from '../../locales/TranslateStatic'
import { Link } from 'react-router-dom';
import LazyLoad from "react-lazyload";
import { _multiSliderlistesi } from './services'
import { Helmet } from 'react-helmet'
const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 1s infinite;
`;


const params = {
    speed: 800,
    loop: false,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true
    }
}
export default function Slider(props) {
    const [data, setState] = useState(false);
    const refPlaceholder = useRef();

    const removePlaceholder = () => {
        refPlaceholder.current.remove();
    };
    useEffect(() => {
        _multiSliderlistesi()
            .then(list => {
                setState(list.data.data)
            })
    }, [props.apiUrl])
    return (
        <SliderSC name="slider">
            {data ? <Swiper {...params}>
                {data ? data.sort((c,d)=> c.sira_no - d.sira_no).map((x, i) => {
                    return <div key={i}>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                            <Parallax
                                onLoad={removePlaceholder}
                                onError={removePlaceholder}
                                key={i}
                                bgImage={`${props.apiUrl}/public/uploads/multislider/photo-` + x.image}
                                blur={{ min: -1, max: 3 }}
                                strength={400}>
                                {x.baslik_tr ? <Spanner key={i}>
                                    <Helmet>
                                        <title>{x.baslik_en}</title>
                                        <meta name="description" content={x.baslik_en} />
                                        <meta property="og:url" content={`http://cariagolf.com${x.link}`} />
                                        <meta property="og:type" content="website" />
                                        <meta property="og:title"
                                            content={x.baslik_en}
                                        />
                                        <meta property="og:description"
                                            content={x.aciklama_en}
                                        />
                                        <meta property="og:image"
                                            content={`${props.apiUrl}/public/uploads/multislider/photo-` + x.image} />
                                        <meta
                                            name="keywords"
                                            content={data.name} />

                                    </Helmet>
                                    <UstBaslik>
                                        <Translate deger={x} string="baslik_" />
                                    </UstBaslik>
                                    <AltBaslik>
                                        <Translate deger={x} string="aciklama_" />
                                    </AltBaslik>
                                    {x.link ? <Altlink key={i}>
                                        <Link key={i} to={x.link}>
                                            <TranslateStatic key={i} string="detay" />
                                        </Link>
                                    </Altlink> : false}
                                </Spanner> : false}

                            </Parallax>
                        </LazyLoad>
                    </div>
                }) : false}
            </Swiper> : false}

        </SliderSC>
    )
}
