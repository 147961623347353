import styled from 'styled-components';

export const IcerikSC = styled.div`
display:flex;
/* background-color:white; */
/* width:25%; */
padding:10px 0px;
flex-direction:column;
border-radius:4px;
flex-basis:100%;
h3{
    text-align:center;
    color:#009900;
}
h4{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    background-color:#f66400;
    padding:5px !important;
    box-sizing:border-box;
    color:white;
    font-size:11pt;
    font-weight:normal;
    span{
          flex: 2 2 20%;
          &:last-child{
            flex: 1 1 25%;
          }
      }
}
ul{
    margin:0;
    padding:0;
    li{
       list-style:none;
       padding:5px !important;
       box-sizing:border-box;
       display:flex;
    flex-direction:row;
    font-size:10.5pt;
    justify-content:space-between;
    flex-wrap:nowrap;
    &:nth-child(odd){
        background-color:white;
    }
      span{
        flex: 2 2 20%;
        font-size:10.5pt;
          a{
            background-color:#009900;
            font-size:10.5pt;
            border-radius:4px;
            color:white;
            padding:2px;
            text-decoration:none;
            width:100%;
            svg{
              vertical-align:middle;
            }
          }
          &:last-child{
            flex: 1 1 25%;
          }
      }
    }
}
`