import styled from 'styled-components';

export const WhatsAppSC = styled.div`
    position:fixed;
    bottom:0;
    right: 0;
    padding: 10px;
    box-sizing: border-box;
    z-index: 1000;
    a{
        color:black;
        &:visited{
            span{
                display: none;
            }
        }
        img{
            width: 80px;
            float: right;
            animation: 1s fadeIn;
            animation-delay: 3s;
            animation-fill-mode: forwards;
            visibility: hidden;
        }
        span{
            float: left;
            margin-top:20px;
            padding: 10px;
            border-radius: 10px;
            background-color: white;
            box-sizing: border-box;
            animation: 1s fadeIn;
            animation-delay: 3s;
            animation-fill-mode: forwards;
            
            visibility: hidden;
        }
    }
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
`