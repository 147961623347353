import styled from 'styled-components'

const LogoSC = styled.div`
    width: auto;
    display:flex;
    flex-direction:row;
    align-self: stretch;
    img {
      width:80px;
      object-fit: contain;
      object-position: left;
    }
    div{
      margin:0px 10px;
    }
    @media screen and (max-width: 1100px) {
    /* width: 60px; */
    margin-left:20px;
    padding: 5px;
    box-sizing:border-box;
  }
`
export default LogoSC;