import React, { useEffect, useState, useContext, Fragment } from 'react';
import styled from 'styled-components'
import { useParams, Link } from "react-router-dom";
import { Detail, IncCourse, PageDetailSC, LeftSC, RightSC, PaketBaslik, Wrapper, PaketImg } from './style'
import Gallery from './itemgallery'
import Saha from './saha'
import { EditorState, convertToRaw, convertFromHTML, CompositeDecorator, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LocaleContext } from "../../store/url";
import Pakets from './pakets'
import Slide from 'react-reveal/Slide';
import TranslateStatic from '../../locales/TranslateStatic'
import mapConverter from '../../utils/mapConverter'
import { Helmet } from 'react-helmet'
import Prices from './prices'
import Icerik from './icerik'
import { _paketDetay } from './services'
export default function PageDetail(props) {
    let { id } = useParams();
    const [data, setState] = useState(false);
    const locale = useContext(LocaleContext);
    console.log(locale)
    useEffect(() => {
        window.scrollTo(0, 0)
        _paketDetay(id)
            .then(list => {
                console.log(list)
                setState(list.data.data)
            })
    }, [props.apiUrl, id]);
    function _editorStateChanger(data, alan) {
        const veri = data[`${alan}${locale}`];
        const blocksFromHtml = htmlToDraft(veri);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        console.log(editorState)
        return editorState;
    }
    return <Fragment>
        {data ? <Helmet>
            <title>{data.name}</title>
            <meta name="description" content={data.name} />
            <meta property="og:url" content={`http://cariagolf.com/paket/${id}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title"
                content={data.name}
            />
            <meta property="og:description"
                content={data.name}
            />
            <meta
                name="keywords"
                content={data.name} />            {/* <meta property="og:image"
                content={`${props.apiUrl}public/uploads/photo-` + x.image} /> */}

        </Helmet> : false}
        <PaketImg>
            <PaketBaslik>{data ? data.name : false}</PaketBaslik>
        </PaketImg>
        <Slide bottom>
            <PageDetailSC>
                <Wrapper>
                    <LeftSC>
                        <h4>{data ? data.name : false}</h4>
                        {data && data.hotel[0] ? <Gallery
                            apiUrl={props.apiUrl}
                            hotel_id={data.hotel[0].id}
                            gallery_id={data.hotel[0].gallery[0] ? data.hotel[0].gallery[0].id : false}
                        /> : false}
                        {data ? <Detail>
                            {data.icerik ? <IncCourse>
                                <Icerik data={data.icerik} />
                                {data.saha ? <Saha data={data.saha} /> : false}
                            </IncCourse> : false}
                        </Detail> : false}
                        {data.prices ? <Prices apiUrl={props.apiUrl} data={data.prices} paket_id={data.id} /> : false}
                        {data ? <div>
                            <Editor
                                toolbarHidden
                                editorState={_editorStateChanger(data.aciklama[0], '')}
                                readOnly
                            />
                        </div> : false}

                        {data.hotel && data.hotel[0] ? <Fragment>
                            {/* <PaketBaslik2>Location</PaketBaslik2> */}
                            {data.hotel[0].map ? <iframe
                                title="otel konum"
                                src={mapConverter(data.hotel[0].map)}
                                allowFullScreen=""
                                aria-hidden="false"
                                frameBorder="0"
                                tabindex="0" /> : false}
                        </Fragment> : false}

                    </LeftSC>
                    <RightSC>
                        <Link to={`/talep/${data.id}`}><TranslateStatic string="rezervasyon" /></Link>
                        <h3><TranslateStatic string="benzer_paketler" /></h3>
                        <Pakets apiUrl={props.apiUrl} />
                    </RightSC>
                </Wrapper>
            </PageDetailSC>
        </Slide>
    </Fragment>
}