import styled from 'styled-components'

export const FilterWrap = styled.div`
flex-basis:100%;
padding:10px 100px;
box-sizing:border-box;
flex-direction:row;
display:flex;
justify-content:center;
flex-wrap:wrap;
@media screen and (max-width:1200px){
    padding:10px;
}
`
export const SelectorWrap = styled.div`
display:flex;
flex-direction:row;
background:white;
align-items:center;
box-sizing:border-box;
border:1px solid #353535;
margin:0px 5px;
border-radius:4px;
padding:0px 10px;
@media screen and (max-width:1200px){
    flex-basis:100%;
    margin:5px;
    select{
        flex-basis: 100%;
    }
}
svg{
    color:#353535;
}
`
export const Selector = styled.select`
padding:10px;
margin-left:2px;
color:#353535;
background:white;
border-radius:4px;
border:none;
&:focus{
    outline:none;
}
option{
    padding:10px;
}
`