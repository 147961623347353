import React, { useContext } from 'react';
import { AboutWrapper, Left, Right, Baslik, ContentWrapper, ItemWrapper, Item, ItemLeft, ItemRight } from './styles'
import Yerel from '../../assets/070-gps.png'
import Deneyim from '../../assets/097-checklist-1.png'
import Aliskanlik from '../../assets/083-sign.png'
import Speak from '../../assets/048-monitor.png'
import Support from '../../assets/125-time.png'
import FirstAid from '../../assets/050-first-aid-kit.png'
import Tour from '../../assets/198-golf.png'
import Teetimebg from '../../assets/hakkimizda-bg.webp'
import { LocaleContext } from "../../store/url";

export default function () {
    const locale = useContext(LocaleContext);
    return locale === 'en'&& < AboutWrapper >
        <ContentWrapper>
            <Left>
                <img src={Teetimebg} alt="hakkimizda"/>
            </Left>
            <Right>
                <Baslik>About Us</Baslik>
                <p>We are a Turkish-Czech travel agency specializing in sports activities held mainly in Turkey. Since .... we provide services in the field of organizing sports meetings, tourist activities, ski touring and cycling tours. As we also became passionate golfers, we decided to join services in organizing golf tours to our portfolio too.</p>
                <p>Thanks to our experience and know-how, we will create an experience that you will never forget. You don't want to play golf every day and still want to do some activity? No problem, we will arrange for you a one-day or multi-day trek around the area, a trip for culture or even a bike ride. It just depends on your wishes. We approach all clients individually and provide exclusive care.</p>
                <p>In our offer you will find only top hotels that we know well and we know ,that you will feel good in there.
                We will arrange for you everything from accommodation, tee time on all courses in Belek, luxury private transfer from and to the airport, golf lessons, rental of golf clubs to and even organization of a golf tournament just for you and your friends.
</p>
                <p>So don't hesitate and come with us to experience the difference!</p>
                {/* <p>Our advantages:</p>
<ul>
    <li>Background of a stable and insured Turkish office</li>
    <li>Many years of experience in organizing sports activities in Turkey</li>
    <li>Knowledge of Turkey and habits</li>
    <li>We speak Czech, English, German, Turkish</li>
    <li>We are on the phone for you 24 hours (assistance service)</li>
    <li>In case of an accident or problems, we have a contractually secured private hospital cooperating with Czech insurance companies</li>
    <li>We create tours individually, create your own date and type of activities and wishes and we will prepare you an offer.</li>
</ul> */}

            </Right>
        </ContentWrapper>
        <ItemWrapper>
            <Item>
                <ItemLeft><img src={Tour} alt="tour"/></ItemLeft>
                <ItemRight>
                    <h4>
                        We create tours individually, create your own date and type of activities and wishes and we will prepare you an offer.
                    </h4>
                </ItemRight>
            </Item>
            <Item>
                <ItemLeft><img src={FirstAid} alt="firstaid"/></ItemLeft>
                <ItemRight>
                    <h4>
                        In case of an accident or problems, we have a contractually secured private hospital cooperating with Czech insurance companies
                    </h4>
                </ItemRight>
            </Item>
            <Item>
                <ItemLeft><img src={Deneyim} alt="experience"/></ItemLeft>
                <ItemRight>
                    <h4>
                        Many years of experience in organizing sports activities in Turkey
                    </h4>
                </ItemRight>
            </Item>
          
            <Item>
                <ItemLeft><img src={Yerel} alt="local"/></ItemLeft>
                <ItemRight>
                    <h4>
                        Background of a stable and insured Turkish office
                    </h4>
                </ItemRight>
            </Item>

            <Item>
                <ItemLeft><img src={Support} alt="support"/></ItemLeft>
                <ItemRight>
                    <h4>
                        We are on the phone for you 24 hours (assistance service)
                    </h4>
                </ItemRight>
            </Item>
           
           
            <Item>
                <ItemLeft><img src={Speak} alt="speak"/></ItemLeft>
                <ItemRight>
                    <h4>
                        We speak Czech, English, German, Turkish
                    </h4>
                </ItemRight>
            </Item>
            <Item>
                <ItemLeft><img src={Aliskanlik} alt="aliskanlik"/></ItemLeft>
                <ItemRight>
                    <h4>
                        Knowledge of Turkey and habits
                    </h4>
                </ItemRight>
            </Item>
        </ItemWrapper>
    </AboutWrapper >
}