import styled from 'styled-components'
import Servicebg from '../../assets/service-bg.jpg'
export const AboutWrapper = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
`
export const ContentWrapper = styled.div`
padding:100px 120px;
display:flex;
flex-direction:row;
flex-wrap:wrap;
@media (max-width: 1000px) {
    padding:50px;
  }
`
export const Left = styled.div`
  flex-basis:50%;
  overflow:hidden;
  border-radius:4px;
  img{
      width:100%;
      height:100%;
      object-fit:cover;
  }
  @media screen and (max-width: 1000px) {
    flex-basis:100%;
  }
  `
export const Right = styled.div`
   flex-basis:50%;
   padding:0px 0px 0px 60px;
   box-sizing:border-box;
   @media screen and (max-width: 1000px) {
    flex-basis:100%;
    padding:0px 0px 0px 0px;
  }
   h2{
       font-family:'Playfair Display';
       text-align:center;
       font-style:italic;
       color:#353535;
   }
   p{
       text-align:justify;
       font-size:12px;
       }
       ul{
           font-size:12px;
       }
   `
export const Baslik = styled.h1`
   cursor:default;
   text-align:center;
   font-family: 'Playfair Display';
   font-style: italic;
   position: relative;
   color:#353535;
   padding-bottom:10px;
   transition:300ms;
   :before {
       transition:300ms;
     content : "";
     position: absolute;
     bottom  : 0;
     left:48%;
     height  : 1px;
     width   : 5%;  /* or 100px */
     border-bottom:2px solid #85a093;
   }
   &:hover{
       transition:300ms;
       :before {
           transition:300ms;
           content : "";
           position: absolute;
           bottom  : 0;
           left:43%;
           height  : 1px;
           width   : 15%;  /* or 100px */
           border-bottom:2px solid #85a093;
       }
   }
   `
export const ItemWrapper = styled.div`
   background-image:url(${Servicebg});
   flex-basis:100%;
   height:auto;
   display:flex;
   padding:50px 120px;
   flex-direction:row;
   flex-wrap:wrap;
   justify-content:space-evenly;
   @media (max-width: 1000px) {
    padding:50px;
  }
   `
export const Item = styled.div`
   display:flex;
   flex-basis:30%;
   flex-direction:row;
   margin:10px 0px;
   @media (max-width: 1000px) {
    flex-basis:100%;
  }
   `
export const ItemLeft = styled.div`
   img{
    width:50px;
   }
   `
export const ItemRight = styled.div`
   padding:0px 0px 0px 30px;
    h4{
        font-family:'Playfair Display';
        margin:0;
        color:white;
        transition:500ms;
        cursor: pointer;
        &:hover{
            transition:500ms;
            color:black;
        }
    }
   `