import React, { useEffect, useState } from 'react'
import { SubscribeSC, Baslik, AltBaslik, ButtonSC } from './style';
import Fade from 'react-reveal/Fade';
import TranslateStatic from '../../locales/TranslateStatic'

export default function Subscribe() {
  function _katil() {
    console.group('tik')
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
      
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      console.log("grant")
      let notification = new Notification("Golf fırsatları artık seninle.");
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission === "denied") {
      console.log("den")
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        
        console.log(permission)
        if (permission === "granted") {
          let notification = new Notification("Hi there!");
          setPerm(true);
        }else{
          Notification.requestPermission();
        }
      });
    }else{
      Notification.requestPermission().then((permission)=>setPerm(true));
    }
  }
  useEffect(()=>{
    // let izin =  Notification.permission
      if (Notification.permission === "granted") {
        setPerm(true);
      }else{
        setPerm(false)
      }
  },[])
  const [perm, setPerm] =useState(false);
  return <Fade bottom>
    <SubscribeSC>
      <Fade bottom cascade>
        <Baslik><TranslateStatic string="bildirim_baslik" /></Baslik>
        <AltBaslik>
        <TranslateStatic string="bildirim_alt_baslik" />
        </AltBaslik>
        {perm?<ButtonSC><TranslateStatic string="abone_olundu" /></ButtonSC>:<ButtonSC onClick={() => _katil()}><TranslateStatic string="katil" /></ButtonSC>}
      
      </Fade>
    </SubscribeSC>
  </Fade>
}