import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import NT from './routes/notsupported'
import App from './App';
import {isIE} from 'react-device-detect';
//import ReactGA from 'react-ga'
// import { subscribeUser } from './subscribe';
// import * as serviceWorker from './serviceWorker';

// ReactGA.initialize('UA-58234430-13')
ReactDOM.render(isIE?<NT/>:<App />, document.getElementById('root'));

