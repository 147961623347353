import React from 'react';
import { FilterWrap, Selector, SelectorWrap } from './styles'
import { MdHotel} from "react-icons/md";
import { GiGolfFlag } from "react-icons/gi";
import { BiEuro } from "react-icons/bi";
export default (props) => {
    return <FilterWrap>
        <SelectorWrap>

            <GiGolfFlag />
            <Selector defaultValue={props.roundid} onChange={(e) => props.change(e)}>
                <option value="all">All Golf Packages</option>
                {props.roundlist ? props.roundlist.map((x, i) => {
                    return <option key={i} value={x[0]}>
                        {x[0] === "0" ? "Unlimited Golf Packages" : x[0] !== "1" ? `${x[0]} Rounds Golf Packages` : `${x[0]} Round Golf Packages`}
                    </option>
                }) : false}
            </Selector>
        </SelectorWrap>
        <SelectorWrap>
            <MdHotel />
            <Selector defaultValue={props.hotelname} onChange={(e) => props.change2(e)}>
                <option value="all">All Hotels & Resorts</option>
                {props.data ? props.data.map((x, i) => {
                    return <option key={i} value={x[0]}>{x[0]}</option>
                }) : false}
            </Selector>
        </SelectorWrap>
        <SelectorWrap>
            <BiEuro />
            <Selector defaultValue={props.sortValue} onChange={(e) => props.changeSort(e)}>
                <option value="hidden" disabled hidden>Sort By</option>
                <option value="all">Sort by: Featured</option>
                <option value="lowtohigh">Price : Low to High</option>
                <option value="hightolow">Price : High to Low</option>
            </Selector>
        </SelectorWrap>

    </FilterWrap>
}