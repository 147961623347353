import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { MdMore } from "react-icons/md";
// import 'swiper/swiper.scss'
import 'swiper/css/swiper.css';
import TranslateStatic from '../../locales/TranslateStatic'
import { _imagelistesi } from './services'
import { LocaleContext } from '../../store/url'
const GallerySC = styled.div`
width:100%;
height:500px;
transition:300ms;
position:relative;
&::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #cca643, #353535);
    /* background-color:rgba(0,0,0,0.3); */
    transition:300ms;
    opacity: .5;
    z-index: 150;
}
&:hover{
    transition:300ms;
    &::after{
        transition:300ms;
        display:none;
    }
}
cursor:pointer;
img{
 width:100%;
 height:500px;
 object-fit:cover;
}
`;
const CustomButton = styled.div`
color:#85a093;
opacity:0.8;
cursor: pointer;
&:hover{
    opacity:1;
    transition:300ms;
}
`
const params = {
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    renderPrevButton: () => <CustomButton className="swiper-button-prev"></CustomButton>,
    renderNextButton: () => <CustomButton className="swiper-button-next"></CustomButton>,
}
const SpanAbs = styled.div`
    width:100%;
    position:absolute;
    top:0;
    padding-top:20px;
    font-size:24px;
    text-shadow: 2px 2px 5px black;
    text-align:center;
    color:white;
    font-weight:500;
    font-family:'Oswald';
    z-index:200;
    svg{
        color:white;
    }
`
const ButtonAbs = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    position:absolute;
    bottom:0;
    padding:20px;
    z-index:200;
    box-sizing:border-box;
    a{
        float:left;
        margin:0px auto;
        border:none;
        border-radius:3px;
        font-family:'Oswald';
        text-transform:uppercase;
        text-decoration:none;
        font-size:14px;
        font-weight:600;
        background-color:#849f93;
        color:white;
        padding:10px 30px;
        transition:300ms;
        svg{
                color:white;
                margin-right:10px;
            }
        &:last-child{
            background:white;
            color:#849f93;
            svg{
                color:#849f93;
                margin-right:10px;
            }
            &:hover{
            transition:300ms;
            background-color:#cca643;
            color:white;
            svg{
                color:white;
            }
            }&:focus{
                border:none;
                outline:none;
            }
        }
        
        &:hover{
        transition:300ms;
        background-color:#cca643;
        }&:focus{
            border:none;
            outline:none;
        }
    }
`
const Linker = styled.a`
float:left;
margin:0px auto;
border:none;
border-radius:3px;
font-family:'Oswald';
text-transform:uppercase;
text-decoration:none;
font-size:14px;
font-weight:600;
background-color:#849f93;
color:white;
padding:10px 30px;
transition:300ms;
svg{
        color:white;
        margin-right:10px;
    }
&:hover{
   transition:300ms;
   background-color:#cca643;
}&:focus{
    border:none;
    outline:none;
}
`
const API_URL = LocaleContext._currentValue;
export default function ItemGallery(props) {
    const [state, setState] = useState(false);
    useEffect(() => {
        _imagelistesi(props.gallery_id)
            .then(list => {
                setState(list.data.data)
            })
    }, [props.gallery_id])
    return <GallerySC>
        <SpanAbs><span>{props.name}</span></SpanAbs>
        <ButtonAbs>

            {/* <Link to={{ pathname: "https://example.zendesk.com/hc/en-us/articles/123456789-Privacy-Policies" }} target="_blank" >
        <TiLocation /><TranslateStatic string="map"/>
            </Link> */}
            {/* <a href="www.google.com" target='_blank' rel="nofollow">
                <TiLocation /><TranslateStatic string="map"/>
            </a> */}
            <Link to={`/hoteldetail/${props.hotel_id}`}>
                <MdMore /><TranslateStatic string="more" />
            </Link>
        </ButtonAbs>
        {state ? <Swiper {...params}>
            {state
                .sort((a, b) => (a.set_cover === b.set_cover) ? 0 : a ? -1 : 1)
                .sort((c, d) => c.sira_no - d.sira_no)
                .map((x, i) => {
                    return <div key={i}>
                        <img src={`${API_URL}/public/uploads/` + x.name} alt={x.name} />
                    </div>
                })}
        </Swiper> : false}</GallerySC>
}