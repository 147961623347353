import React from 'react';
import TranslateStatic from './translate'
import { ContactUsSC, Left, Right, Baslik, List } from './style'




export default function ContactUs() {
    return (
        <ContactUsSC name="deneme">
            <Left>
                <Baslik><TranslateStatic string={"contact_us"} /></Baslik>
                <List>
                    <li><TranslateStatic string={"email"} />: info@cariagolf.com</li>
                    <li><TranslateStatic string={"address"} />: Güzeloluk Mh. Yuvam Sit. 1820 Sk. </li>
                    <li>A Blok D.3 TR-07230 Muratpaşa - ANTALYA</li>
                    <li><TranslateStatic string={"phone"} />: +90 242 999 10 23</li>
                    <li><TranslateStatic string={"whatsapp"} />: +90 532 246 69 36</li>
                </List>
            </Left>
            <Right>

                <iframe
                    title="Konum"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.9255984110437!2d30.757961114687824!3d36.86819967156985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c39b297400ba6d%3A0xcecc9ccf462efb79!2sCaria%20Pan%20Travel!5e0!3m2!1str!2str!4v1593125252011!5m2!1str!2str"
                    allowFullScreen=""
                    aria-hidden="false"
                    frameBorder="0"
                    tabIndex="0" />
                
            </Right>
        </ContactUsSC>
    )
}