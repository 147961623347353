import React,{useState} from 'react';
import {Link} from 'react-router-dom'
import Nav from './Nav'
import SubNav from '../SubNavigation'
import SubNavV2 from '../SubNavigationV2'
import styled from 'styled-components'

// import LanguagePicker from "../../LanguagePicker";
import LangChanger from './LangChanger'

import SmoothScrool from './scroller'
import {HeaderSC,TakeTeeTime} from './style'
import Logo from './Logo'
import Translate from '../../locales/TranslateStatic';

export default function Header(props) {
  const [show, setShow] = useState(false);
  return (<HeaderSC>
    <Logo/>
    <Nav routes={props.routes} apiUrl={props.apiUrl} click={setShow}>
      <LangChanger lang={props.lang} changeLanguage={props.changeLanguage} />
    </Nav>
    <TakeTeeTime onClick={() => SmoothScrool.scrollTo('tee', 40)}><Translate string="teetime"/></TakeTeeTime>
  </HeaderSC>)

}