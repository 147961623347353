import React, { PureComponent } from "react";
import { LocaleContext } from "../../../store/url";

import en from "./en.json";
import tr from "./tr.json";
import de from "./de.json";
import cz from "./cz.json";
import ru from "./ru.json";

export default class Translate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      langs: {
        en,
        tr,
        de,
        ru,
        cz,
      }
    };
  }
  render() {
      const {langs} = this.state 
      const {string} = this.props
    
    return (
      <LocaleContext.Consumer>
        {value => langs[value][string]}
      </LocaleContext.Consumer>
    );
  }
}