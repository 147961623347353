import styled from 'styled-components';


export const Products = styled.div`
/* width:100%; */
/* min-height:calc( 100vh - 96px); */
background-color:#f8f4ea;
padding:50px 120px;
display:flex;
flex-direction:column;
align-content:space-between;
@media screen and (max-width: 1000px) {
    padding:50px;
  }
`
export const Baslik = styled.h1`
cursor:default;
text-align:center;
font-family: 'Playfair Display';
font-style: italic;
position: relative;
color:#353535;
padding-bottom:10px;
transition:300ms;
:before {
    transition:300ms;
  content : "";
  position: absolute;
  bottom  : 0;
  left:48%;
  height  : 1px;
  width   : 5%;  /* or 100px */
  border-bottom:2px solid #cca643;
}
&:hover{
    transition:300ms;
    :before {
        transition:300ms;
        content : "";
        position: absolute;
        bottom  : 0;
        left:43%;
        height  : 1px;
        width   : 15%;  /* or 100px */
        border-bottom:2px solid #cca643;
    }
}
`
export const ItemWrapper = styled.div`
width:100%;
/* background:blue; */
display:flex;
justify-content:center;
flex-direction:row;
flex-wrap:wrap;
align-content:space-between;
`
export const Item = styled.div`
margin-bottom:25px;
width:30%;
height:auto;
display:flex;
flex-direction:column;
border-radius:3px;
overflow:hidden;
background-color:white;
/* background:yellow; */
/* -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.50);
-moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.50);
box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.50); */
transition:300ms;
margin:10px;
&:hover{
    transition:300ms;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);
-moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);
box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);
}
@media screen and (max-width: 1000px) {
    width:100%;
  }
`
export const Content = styled.div`
width:100%;
display: -webkit-box;
max-height: 209.2px;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
padding:30px 10px 0px;
box-sizing:border-box;
overflow:hidden;
h4{
    color: #353535;
    font-family:'Playfair Display';
    font-weight:700;
    font-size:16px;
    margin:0;
    text-align:center;
    padding:0;
    a{
        text-decoration:none;
        color:inherit;
    }
    &:hover{
        transition:300ms;
        color: #cca643;
    }
}
h3{
    color: #cca643;
    font-family:'Playfair Display';
    font-weight:700;
    font-size:18px;
    margin:0;
    text-align:center;
    padding:10px 0px;
    &:hover{
        transition:300ms;
        color: #353535;
    }
}
p{
    margin:0px;
    text-align:center;
}
`
export const ButtonSC = styled.button`
    margin:0px auto;
    border:none;
    border-radius:3px;
    font-family:'Oswald';
    text-transform:uppercase;
    font-size:16px;
    font-weight:600;
    background-color:#cca643;
    color:white;
    padding:10px 30px;
    transition:300ms;
    &:hover{
        transition:300ms;
        background-color:#85a093;
    }&:focus{
        border:none;
        outline:none;
    }
`
export const ButtonWrapperSC = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-bottom:20px;
a{
    border:none;
    text-decoration:none;
    padding:10px 30px;
    width:auto;
    border-radius:3px;
    text-transform:uppercase;
    font-size:12px;
    font-family:'Oswald';
    letter-spacing:1px;
    transition:300ms;
    margin:0px 10px;
    &:first-child{
        background-color:#eff6f3;
        color:#353535;
        &:hover{
            letter-spacing:2px;
            color:white;
            background-color:#cca643;
            transition:300ms;
        }
    }
    &:last-child{
        background-color:#85a093;
        color:white;
        &:hover{
            letter-spacing:2px;
            background-color:#cca643;
            transition:300ms;
        }
    }
}
`