import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { H3, H5, NMSC } from './styles'


export default function NoMatch() {
  let history = useHistory();
  useEffect(() => {
    const timer = setTimeout(() => {
      history.push("/");
    }, 5000);
    return () => clearTimeout(timer);
  }, [history]);
  return (
    <NMSC>
      <H3>
        Gitmek istediğiniz sayfa sistemimizde bulunamamıştır.
        </H3>
      <H5>5 saniye içinde ana sayfaya yönlendirileceksiniz.</H5>
    </NMSC>
  );
}