import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Swiper from 'react-id-swiper';
// import 'swiper/swiper.scss'
import 'swiper/css/swiper.css';
import LazyLoad from "react-lazyload";
const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 1s infinite;
`;

export default function ItemGallery(props) {

    const refPlaceholder = useRef();

    const removePlaceholder = () => {
      
            refPlaceholder.current.remove();
    
        
    };
   

    
   
    return  <div>
                <Placeholder ref={refPlaceholder} />
                <LazyLoad>
                    <img
                    
                        onLoad={removePlaceholder}
                        onError={removePlaceholder}
                        src={`${props.apiUrl}/public/uploads/` + props.name}
                        alt="" />
                </LazyLoad>
            </div>
        
}