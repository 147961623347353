import React, { useEffect, useState } from 'react';
import { WhatsAppSC } from './styles'
import {_whatsapplistesi} from './services'
export default function WhatsApp(props) {
  const [whatsapp, setWhatsApp] = useState(false);
  useEffect(() => {
    _whatsapplistesi()
      .then(list => setWhatsApp(list.data.data[0].trackedvalue))
  }, [props.apiUrl])
  return whatsapp ? <WhatsAppSC>
    <a href={`https://wa.me/90${whatsapp}`} target="_blank" rel="noopener noreferrer">
      <img src="https://seeklogo.net/wp-content/uploads/2018/10/whatsapp-logo.png" alt="whatsapp" />
      {/* <span>Hello, how can we help you?</span> */}
    </a>
  </WhatsAppSC> : false
}