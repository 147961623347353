import styled from 'styled-components';

export const HeaderSC = styled.header`
  width: 100%;
  float: left;
  padding: 10px 40px;
  position: fixed;
  display: flex;
  z-index: 100;
  background: white;
  box-sizing:border-box;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  font-family: 'Oswald', sans-serif;
  -webkit-box-shadow: 0px 2px 12px -1px rgba(128, 128, 128, 0.27);
  -moz-box-shadow: 0px 2px 12px -1px rgba(128, 128, 128, 0.27);
  box-shadow: 0px 2px 12px -1px rgba(128, 128, 128, 0.27);
  @media screen and (max-width: 1100px) {
    padding: 0px;
    box-sizing:border-box;
  }
  select{
    color:#353535;
  }
  a{
    word-wrap: normal !important;
  }
  .subnav {
    width: 100%;
    float: left;
    padding: 10px 0px;
    ul{
      margin:0;
      padding: 0;
    }
  }
  .dropdown-menu {
    height: 35px;
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
    text-align: left;
    font-size:12px;
    ul {
      margin-block-start: 0;
      position: absolute;
      display: none;
      margin: 0px;
      padding: 0;
      float: left;
    }
    li {
      list-style: none;
      position: relative;
      float: left;
      //width: 200px;
      padding:10px 20px 10px 20px;
      box-sizing: border-box;
      text-decoration: none;
      background-color: none !important;
      background: none !important;
      border: none !important;
      text-decoration: none;
      word-wrap: normal;
      a{
        text-decoration: none;
        /* color:$font-color-2; */
        font-size: 14px;
        font-weight:600;
        color:#353535;
        font-style:normal;
        word-wrap: normal;
      }
      &:hover {
        background: blue;
        ul {
          display: block;
        }
        ul ul {
          display: none;
        }
        ul ul ul {
          display: none;
        }
      }
      li {
        text-decoration: none;
       word-wrap: normal;
        a {
          background-color: none !important;
          font-style: normal;
          font-weight: 600;
          text-decoration: none;
          word-wrap: normal;
          &:hover {
            text-decoration: underline $brand-color-2;
            color: $brand-color-2 !important;
          }
        }
        &:hover{
          ul {
            margin-left: 100px; /*This must be width of menu box*/
            margin-top: -35px;
            display: block;
          }
        }
        li {
          text-decoration: none;
          word-break: keep-all;
          a {
            background-color: none !important;
            color: $font-color-1 !important;
            text-decoration: none;
            word-wrap: normal;
            &:hover {
              text-decoration: underline $brand-color-2;
              color: $brand-color-2 !important;
              text-decoration: none;
            }
          }
          &:hover{
            ul {
              margin-left: 300px; /*This must be width of menu box*/
              margin-top: -35px;
              display: block;
            }
          }
        }
      }
    }
  }




  .dropdown-submenu {
    position: absolute;
    left: 0px;
    top: 0px;
  }
  
  @media screen and (max-width: 1100px) {
    
    .logo{
      width: 100px;
    }
  }
  @media screen and (min-width: 1200px) {
    float: right;
  }
`
export const TakeTeeTime = styled.div`
color:white;
text-decoration:none;
list-style:none;
cursor:pointer;
background:#cca643;
font-size:16px;
padding:10px 30px;
border-radius:3px;
transition:300ms;
font-weight:500;
letter-spacing: 0.8px;
font-family:'Oswald';
&:hover{
  background-color:#e21e18;
  transition:300ms;
}
`