import React, { useState } from 'react';
import { Wrapper, Baslik, TableHeader,TableHeaderSpan } from './styles'
export default (props) => {
    const [veri] = useState(props.data)

    return veri.map((x, y) => {
        return <Wrapper>
            <Baslik>{x.name}</Baslik>
            <TableHeader>
                {x.cardcolumn.sort(function(a,b) { return a.sira_no - b.sira_no }).map((o, u) => {
                    return <div key={u}>
                        <TableHeaderSpan renk={o.color} textcolor={o.text_color}>{o.name}</TableHeaderSpan>
                        <ul>{o.rows.sort(function(a,b) { return a.sira_no - b.sira_no }).map((h, y) => {
                            return h.value?<li key={y} style={{fontWeight:h.set_bold?'bold':'normal'}}>{h.value}</li>:<li key={y}> 	&nbsp;</li>
                        })}</ul>
                    </div>
                })}
            </TableHeader>
        </Wrapper>
    })
}