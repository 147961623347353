import styled from 'styled-components'

const NavSC = styled.ul`
  box-sizing:border-box;
     @media screen and (max-width:1100px){
        margin:0;
        padding:0;
        display:flex;
        box-sizing:border-box;
        height:100vh;
        flex-direction:column;
        justify-content:stretch;
        align-content:stretch;
        align-items:stretch;
      }
    li {
      float: left;
      list-style: none;
      padding: 2px 10px 0px;
      cursor:pointer;
      @media screen and (max-width:1100px){
        float:none;
        display:flex;
        flex-direction:column;
        flex-basis:100%;
        text-align:center;
        align-content:center;
        align-items:center;
        justify-content:center;
      }
      a,span {
        /* padding: 0 5px; */
        @media screen and (max-width:1100px){
          font-size: 24px;
      }
        text-decoration: none;
        text-transform:uppercase;
        font-size: 14px;
        font-style:normal;
        font-weight:500;
        vertical-align: text-top;
        transition: 300ms;
        color: #777777;
        &:visited {
          color: #777777;
        }
        &:hover {
          transition: 300ms;
          color: black;
          padding-bottom:6px;
          border-bottom:2px solid #cca643;
        }
      }
    }
`
export default NavSC;