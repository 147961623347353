import styled from 'styled-components'
import DetayImg from '../../assets/detayimg.jpg'
export const PageDetailSC = styled.div`
    width:100%;
    /* margin-top:120px; */
    padding:10px 120px 50px;
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    background:#f8f4ea;
    @media screen and ( max-width:1000px ){
        padding:10px 50px;
    }
`;
export const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-basis:100%;
    box-sizing:border-box;
    justify-content:space-between;
`

export const LeftSC = styled.div`
width:59%;
display:flex;
box-sizing:border-box;
/* background:red; */
padding:10px 10px 10px 10px;
background:white;
flex-direction:column;
border-radius:3px;
h3,h4{
    margin:0;
    padding:0;
    padding-bottom:10px;
    text-transform:capitalize;
}
h4{
    font-family:'Oswald';
    letter-spacing:1px;
    font-weight:400;
    font-size:20px;
    color:#85a093;
    /* font-style:italic; */
}
`
export const FullSC = styled.div`
width:100%;
display:flex;
box-sizing:border-box;
/* background:red; */
padding:10px 10px 10px 10px;
background:white;
flex-direction:column;
border-radius:3px;
justify-content:flex-start;
@media screen and ( max-width:1000px ){
        flex-basis:100%;
    }
h3,h4{
    margin:0;
    padding:0;
    padding-bottom:10px;
    text-transform:capitalize;
}
h4{
    font-family:'Oswald';
    letter-spacing:1px;
    font-weight:400;
    font-size:20px;
    color:#85a093;
    /* font-style:italic; */
}
form{
    flex-basis:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    flex-wrap:wrap;
    border:1px solid #eff6f3;
    border-radius:3px;
    padding-bottom:20px;
    label{
        flex-basis:25%;
        @media screen and ( max-width:1000px ){
            flex-basis:100%;
        }
        padding:10px;
        box-sizing:border-box;
        display:flex;
        flex-direction:column;
        span{
            flex-basis:100%;
            padding:5px 0px;
        }
        input{
            padding:10px;
            box-sizing:border-box;
            background:#eff6f3;
            border:none;
            border-radius:3px;
        }
        textarea{
            resize: none;
            padding:10px;
            box-sizing:border-box;
            background:#eff6f3;
            border:none;
            border-radius:3px;
        }
    }
    div{
        flex-basis:50%;
        @media screen and ( max-width:1000px ){
            flex-basis:100%;
        }
    }
    article{
        flex-basis:50%;
        height:150px;
        overflow:hidden;
        @media screen and ( max-width:1000px ){
            flex-basis:100%;
        }
        &:hover{
            height:auto;
        }
    }
}
`
export const RightSC = styled.div`
    border-radius:3px;
    width:39%;
    background:white;
    box-sizing:border-box;
    /* box-sizing:border-box; */
    display:flex;
    flex-direction:column;
    /* background:blue; */
    padding:10px 10px 10px 10px;
    h3{
        text-align:center;
        padding-bottom: 10px;
        margin:0;
        color:#cca643;
        font-family:'Oswald';
        letter-spacing:1px;
        font-weight:400;
        font-size:20px;
    }
`
export const PBaslikSC = styled.p`
    width:100%;
    background-color:white;
    color:#353535;
    text-align:center;
    padding:10px;
    box-sizing:border-box;
    font-weight:700;
    border-radius:3px;
    font-size:24px;
    font-family:'Playfair Display';
    font-style:italic;
    margin:0px;
    margin-bottom:10px;
`
export const Dater = styled.div`
    width:100%;
    text-align:center;
    box-sizing:border-box;
    font-size:14px;
    /* font-family:'Oswald' */
`
export const PaketWrapper = styled.div`
width:100%;
background-color:#eff6f3;
border-radius:3px;
float:left;
padding:20px 20px;
box-sizing:border-box;
display:flex;
flex-direction:column;
`
export const HotelName = styled.div`
width:100%;
    text-align:center;
    box-sizing:border-box;
    font-size:18px;
`
export const PaketImg = styled.div`
    margin-top:90px;
    float:left;
    background-position: 0px -30px !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    display:flex;
    flex-direction:row;
    align-items:center;
    width:100%;
    height:150px;
    background-image:url(${DetayImg});
    @media screen and ( max-width:1000px ){
        background-position: 0px 0px !important;
        margin-top:53px;
        }
        
`
export const PaketBaslik = styled.p`
    flex-basis:100%;
    font-family:'Playfair Display';
    font-size:32px;
    text-align:center;
    font-style:italic;
    color:white;
    font-weight:700;
`
export const ButtonSC = styled.button`
 margin:0px auto;
 border:none;
 border-radius:3px;
 font-family:'Oswald';
 text-transform:uppercase;
 font-size:16px;
 font-weight:600;
 background-color:#85a093;
 color:white;
 padding:10px 30px;
 transition:300ms;
 &:hover{
    transition:300ms;
    background-color:#cca643;
 
 }&:focus{
     border:none;
     outline:none;
 }
`