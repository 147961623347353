import styled from 'styled-components'

export const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    /* float:left; */
    width: 100%;    
    /* height: 60vh; */
    padding:120px 40px;
    box-sizing:border-box;
`