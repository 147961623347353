import styled from 'styled-components'


export const SliderSC = styled.div`
    width: 100%;
    height: calc(100vh - 90px);
    float: left;
    margin-top:90px;
    z-index: 90;
    @media screen and (max-width: 1000px) {
      margin-top:53px;
      height: calc(100vh - 53px);
  }
    cursor:pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit:cover;
      position: absolute;
      top: 0;
      z-index: 10;
      float: left;
    }
    .content {
        float: left;
      padding: 80px 120px;
      box-sizing: border-box;
      width: 100%;
      position: absolute;
      z-index: 20;
      h1 {
        color: #29323c;
      }
      ul {
        li {
          font-size: 16px;
          margin: 5px 0;
          color: #fff;
          list-style: none;
          padding-left: 30px;
        }
      }
      .ask {
        padding: 15px;
        margin-top: 20px;
        float: left;
        box-sizing: border-box;
        background:  #e8262e;
        border-radius: 4px;
        color: #f5f5f5;
        font-weight: bold;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          width: 32px;
          height: 39px;
          background: url('https://medikalista.com/wp-content/themes/screenr/assets/images/flbout.png') no-repeat;
          right: -19px;
          bottom: 6px;
        }
      }
    }
`
export const Par = styled.div`
position:relative;
height: calc( 100vh - 90px );
width:100%;
@media screen and (max-width: 1000px) {
      height: calc(100vh - 53px);
  }
`
export const Content = styled.div`
  position:absolute;
  width:100%;
  height:calc( 100vh - 90px );
  z-index:2000;
  color:white;
  font-family: 'Playfair Display';
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  align-content:center;
  `

export const UstBaslik = styled.div`
  flex-basis:100%;
      text-align:center;
      font-size:70px;
      font-family:'PlayFair Display';
      font-style:italic;
      font-weight:700;
      float:left;
      margin:0px;
      `
export const AltBaslik = styled.div`
      flex-basis:100%;
      text-align:center;
      font-size:32px;
      font-family:'Oswald';
      font-weight:400;
      margin:0px;
      float:left;
      a{
        color:white;
        &:visited{
          color:white;
        }
      }
      
      `
export const Altlink = styled.div`
      flex-basis:100%;
      display:flex;
      flex-direction:row;
      justify-content:center;
      color:white;
      a{
        text-align:center;
        font-size:18px;
        font-family:'Oswald';
        font-weight:400;
        text-decoration:none;
        color:white;
          &:visited{
              color:white;
          }
        }
      `
export const LeftImg = styled.div`
      position:absolute;
      width:100px;
      height:200px;
      z-index:30000;
      left:10%;
      img{
        object-fit:contain;
      }
      `
export const RightImg = styled.div`
        position:absolute;
        width:100px;
        height:200px;
        z-index:30000;
        right:10%;
        img{
          object-fit:contain;
        }
        `