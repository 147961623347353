import React from 'react';
import { IcerikSC } from './styles'
import { FaCheck } from "react-icons/fa";
import TranslateStatic from './translate'
export default (props) => {
    return <IcerikSC>
        {/*TODO: whatsinclude diğer dil karşılıkları*/}
        <h3><TranslateStatic string="whats_include" /></h3>
        <ul>
            {props.data.sort((c, d) => c.sira_no - d.sira_no).map((x, i) => {
                return x.name_long?<li><FaCheck /> {x.name_long}</li>:false
            })}
        </ul>

    </IcerikSC>
}