import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: left;
  width: 100%;
  padding:0px;
  }
  body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
body::-webkit-scrollbar-thumb:hover{
  transition: 300ms;
  background-color: #85a093;
}

body::-webkit-scrollbar-thumb {
  transition: 300ms;
background-color: #cca643;
outline: 1px solid #cca643;
}
`;

export default GlobalStyle;