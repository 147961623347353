import styled from 'styled-components';

export const Wrapper = styled.div`
display:flex;
flex-direction:row;
justify-content:flex-start;
flex-wrap:wrap;
`
export const Bar = styled.header`
display:flex;
flex-basis:100%;
flex-direction:row;
padding:1em 2em;
justify-content:space-between;
box-sizing:border-box;
align-items:center;
span{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
`
export const In = styled.div`
width:50%;
float:left;
padding:50px 100px;
box-sizing:border-box;
@media screen and (max-width: 1100px) {
    padding: 0px;
    box-sizing:border-box;
    width:100%;
  }
img{
    width:100%;
}
p{
    font-size:3em;
    color:#CCA643;
    font-weight:bold;
    margin-bottom:10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    @media screen and (max-width: 1100px) {
        font-size:2em;
        text-align:center;
  }
}
span{
    width:100%;
    display:block;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    @media screen and (max-width: 1100px) {
        font-size:1em;
        text-align:center;
        margin-bottom:1em;
  }
}
`
export const Logo = styled.header`
width:80px;
float:left;
img{
    width:100%;
    object-fit:contain;
}
`