import React,{ useEffect, useState } from 'react'
import axios from 'axios';
import authHeader from '../utils/authHeader'
import { LocaleContext } from '../store/url'
import { YMInitializer } from 'react-yandex-metrika';
export default function () {
    const [yandex, setYandex] = useState(false);
    const apiUrl = LocaleContext._currentValue;
    useEffect(() => {
        axios.get(apiUrl + '/analytics/name/Yandex', { headers: authHeader() })
            .then(list => {
                if (list.data.data[0])
                    setYandex(parseInt(list.data.data[0].trackedvalue));
            })
    }, [apiUrl])
    return yandex && <YMInitializer accounts={[yandex]} />;
}