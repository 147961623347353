import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Wrapper } from './styles'
import { _searchlistesi } from './services'
import ResultInfo from './resultinfo'
import Resultlist from './resultlist'
export default (props) => {
    let { id } = useParams();
    const [data, setData] = useState(false)
    useEffect(() => {
        _searchlistesi(id).then(res => { 
            const{ hotels,sahas,pakets} = res.data.data
            if(!(Array.isArray(hotels) && hotels.length)&&!(Array.isArray(sahas) && sahas.length)&&!(Array.isArray(pakets) && pakets.length)){
                console.log("asdasd")
                setData(false)
            }else{
                setData(res.data.data);
            }
            })
    }, [id])
    return <Wrapper >
        <ResultInfo search={id} />
        {data ? <>
            {Array.isArray(data.hotels) && data.hotels.length ? <Resultlist link="hoteldetail" baslik="Hotels" data={data.hotels} /> : false}
            {Array.isArray(data.sahas) && data.sahas.length ? <Resultlist link="sahadetail" baslik="Courses" data={data.sahas} /> : false}
            {Array.isArray(data.pakets) && data.pakets.length ? <Resultlist link="paket" baslik="Packages" data={data.pakets} /> : false}
        </> : "There were no results"}
    </Wrapper>
}