import styled from 'styled-components';

export const NMSC = styled.div`
    background-color:white;
    height:50vh;
    padding:90px 120px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    code{
        text-align:center;
        font-size:20px;
    }
`
export const H3 = styled.h3`
text-align:center;
font-family:'Playfair Display';
font-size:24px;
font-style:italic;
color:#85a093;
margin:0;
`
export const H5 = styled.h5`
text-align:center;
font-family:'Oswald';
font-weight:500;
font-size:14px;
color:#3a3737;
`
