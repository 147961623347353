import React, { useEffect } from 'react';
import Slider from '../components/Slider';
import {
  useLocation
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ProductList from '../components/ProductList'
import Subscribe from '../components/Subscribe'
import Hotel from '../components/Hotel'
import Saha from '../components/Saha'
//import MailPanel from '../components/MailPanel';
import styled from 'styled-components'
import TeeTime from '../components/TeeTime'
import SmoothScrool from '../components/Header/scroller'
import ContactUs from '../components/Contact'
import AboutUs from '../components/AboutUs'
const TacosSC = styled.div`
  display:flex;
  flex-direction:column;
`
export default function Tacos(props) {
  let loc = useLocation()
  useEffect(() => {
    setTimeout(function () {
      if (loc.query) {
        if (loc.query !== "slider") {
          SmoothScrool.scrollTo(loc.query, 40)
        }
        else {
          SmoothScrool.scrollTo(loc.query, 90)
        }
      }

    }, 250);
  }, [loc.query])
  return (
    <TacosSC>
      <Helmet>
        <title>Caria Golf</title>
      </Helmet>
      <Slider apiUrl={props.apiUrl} />
      <AboutUs apiUrl={props.apiUrl}/>
      <ProductList apiUrl={props.apiUrl} />
      <Subscribe />
      <Hotel apiUrl={props.apiUrl} />
      <TeeTime apiUrl={props.apiUrl} />
      <Saha apiUrl={props.apiUrl} />
      <ContactUs apiUrl={props.apiUrl}/>
    </TacosSC>
  );
}