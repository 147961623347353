import styled from 'styled-components';

export const LangChangerSC = styled.select`
            font-family:'Oswald';
          border: none;
         margin:0px;
         padding:0px;
         font-size: 14px;
        font-style:normal;
        font-weight:600;
        @media screen and (max-width:1000px){
       /* display:none; */
      }
        cursor:pointer;
          option {
            font-family:'Oswald';
            font-size: 14px;
        font-style:normal;
        font-weight:600;
            /* padding: 10px; */
          }
          .options {
            /* padding: 10px; */
          }
          &:hover {
            border: none;
          }
          &:focus {
            border: none;
            outline: none;
          }
`