import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import analytics from "../services/analytics"

export default function useGoogleAnalytics() {
    const location = useLocation()

    useEffect(() => {
        async function fetchData() {
            // You can await here
             await analytics.init()
            // ...
            console.log("init")
            const currentPath = location.pathname + location.search
            await analytics.sendPageview(currentPath)
        }
        fetchData();
        // await analytics.init()
    }, [location])
    return false
    // React.useEffect(() => {
    //     const currentPath = location.pathname + location.search
    //     analytics.sendPageview(currentPath)
    // }, [location])
}