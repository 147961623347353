import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import GlobalStyle from './globalStyles';
import './styles/app.scss';
import { LocaleContext } from "./store/url";
import { Router } from "react-router-dom";
import StaticRoutes from './routes/static'
import Header from './layouts/header'
import Footer from './layouts/footer'
import Loading from './components/loading';
import { createBrowserHistory as createHistory } from 'history'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationRegister from './NotificationRegister'
import { isIE } from 'react-device-detect';
import landChecker from './utils/langChecker';
import Analytics from './services'
const history = createHistory()


export default (props) => {
  const apiUrl = LocaleContext._currentValue;
  const [preferredLocale, setPreferredLocale] = useState();
  const [routes, setRoutes] = useState(false);

  function changeLanguage(event) {
    setPreferredLocale(event.target.value)
  };
  useEffect(() => {
    setPreferredLocale(landChecker);
    setRoutes([])
  }, [apiUrl])

  return <LocaleContext.Provider value={preferredLocale} >
    <Helmet titleTemplate="Cariagolf | %s" defaultTitle="Cariagolf">
      <meta name="description" content="Best Golf Travel Agency" />
    </Helmet>
    <GlobalStyle />
    <ToastContainer />
    {isIE ? false : <NotificationRegister />}
    <Router onUpdate={() => window.scrollTo(0, 90)} history={history}>
      {routes ? false : <Loading />}
      <Header
        routes={routes}
        changeLanguage={changeLanguage}
      />

      <Analytics />
      {routes ? <StaticRoutes apiUrl={apiUrl} /> : false}
      <Footer routes={routes} apiUrl={apiUrl} />

    </Router>

  </LocaleContext.Provider>
}
