import React, { useState, useEffect, useContext } from 'react'
import Gallery from './itemgallery'
import { Link } from 'react-router-dom'
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LocaleContext } from "../../store/url";
import TranslateStatic from '../../locales/TranslateStatic'
import Fade from 'react-reveal/Fade';
import {
    Products,
    Baslik,
    ItemWrapper,
    Item,
    Content,
    ButtonSC,
    ButtonWrapperSC
} from './styles'
import Filter from './filter'
import { _paketlistesi } from './services'
export default function ProductList(props) {
    const [gosterimSayisi, setGs] = useState(8);
    const [state, setState] = useState(false);
    const [initialState, setInitialState] = useState(false);
    const [filterstate, setFilterState] = useState(false);
    const [hotelName, setHotelName] = useState("all");
    const [roundName, setRoundName] = useState("all");
    const [sortValue, setSortValue] = useState("all");
    const [hotellist, setHotellist] = useState(false);
    const [roundlist, setRoundlist] = useState(false);
    const locale = useContext(LocaleContext);
    console.log(locale)
    function _editorStateChanger(data, alan) {
        console.log(alan)
        const veri = data[`${alan}${locale}`];
        const blocksFromHtml = htmlToDraft(veri);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        console.log(editorState)
        return editorState;
    }
    function listTopfunc(ascending) {

        return function (a, b) {

            // equal items sort equally
            if (a.list_sira_no === b.list_sira_no) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.list_sira_no === null) {
                return 1;
            }
            else if (b.list_sira_no === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (ascending) {
                return a.list_sira_no < b.list_sira_no ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a.list_sira_no < b.list_sira_no ? 1 : -1;
            }

        };

    }
    useEffect(() => {
        _paketlistesi()
            .then(list => {
                let LowPrice = list.data.data.map((x, i) => {
                    let pricearr = x.prices.sort((a, b) => {
                        return a.price < b.price ? -1 : 1;
                    })
                    x.prices = pricearr;

                    return x;
                })

                let sorter = LowPrice.sort(listTopfunc(true))
                setInitialState(sorter);
                console.log(sorter)
                setState(sorter)
                setFilterState(sorter)
                const grouped = groupBy(list.data.data, pet => pet.hotel[0].name);
                const groupedRounds = groupBy(list.data.data, pet => pet.rounds);
                const sortRound = [...groupedRounds].sort();
                setRoundlist([...sortRound]);
                setHotellist([...grouped]);
            })
    }, [props.apiUrl])
    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
    function priceSorter(x) {
        let arr = x.sort((a, b) => {
            if (a.price < b.price) return -1
            return a.price > b.price ? 1 : 0
        })

        return <b>{arr[0].currency === 'USD' ? '$' : arr[0].currency === 'EURO' ? '€' : false}{arr[0].price}</b>
    }
    function filterRound(e) {
        setRoundName(e.target.value);
        if (e.target.value === "all") {
            const grouped = groupBy(state, pet => pet.hotel[0].name);
            setHotellist([...grouped]);
            if (hotelName !== "all") {
                let newRoundArray = state.filter(function (el) {
                    return el.hotel[0].name === hotelName;
                });
                return setFilterState(newRoundArray);
            }
            return setFilterState(state);
        } else {
            let newRoundArray = state.filter(function (el) {
                return el.rounds === e.target.value;
            });
            const grouped = groupBy(newRoundArray, pet => pet.hotel[0].name);
            setHotellist([...grouped]);
            return setFilterState(newRoundArray);
        }

    }
    function filterByHotel(e) {
        setHotelName(e.target.value);
        if (e.target.value !== "all") {
            let newHotelArray = state.filter(function (el) {
                return el.hotel[0].name === e.target.value;
            });
            if (roundName !== "all") {
                let newRoundArray = newHotelArray.filter(function (el) {
                    return el.rounds === roundName;
                });
                setFilterState(newRoundArray)
            } else {
                setFilterState(newHotelArray)
            }
        } else {
            if (roundName !== "all") {
                let newRoundArray = state.filter(function (el) {
                    return el.rounds === roundName;
                });
                setFilterState(newRoundArray)
            } else {
                setFilterState(state)
            }
        }




    }
    function priceSort(e) {
        console.log(state)
        setSortValue(e.target.value);
        if (e.target.value !== "all") {
            if (hotelName !== "all") {
                let newHotelArray = state.filter(function (el) {
                    return el.hotel[0].name === hotelName;
                });
                let newPriceArray = newHotelArray.sort(function (a, b) {
                    return a.prices[0].price < b.prices[0].price ? -1 : 1;
                });
                if (e.target.value === "lowtohigh") {
                    newPriceArray = newHotelArray.sort(function (a, b) {
                        return a.prices[0].price < b.prices[0].price ? -1 : 1;
                    });
                } else if (e.target.value === "hightolow") {
                    newPriceArray = newHotelArray.sort(function (a, b) {
                        return a.prices[0].price < b.prices[0].price ? 1 : -1;
                    });
                }

                if (roundName !== "all") {
                    let newRoundArray = newHotelArray.filter(function (el) {
                        return el.rounds === roundName;
                    });
                    if (e.target.value === "lowtohigh") {
                        newPriceArray = newRoundArray.sort(function (a, b) {
                            return a.prices[0].price < b.prices[0].price ? -1 : 1;
                        });
                    } else if (e.target.value === "hightolow") {
                        newPriceArray = newRoundArray.sort(function (a, b) {
                            return a.prices[0].price < b.prices[0].price ? 1 : -1;
                        });
                    }
                    console.log(newPriceArray)
                    setFilterState(newPriceArray)
                } else {
                    console.log(newPriceArray)
                    setFilterState(newPriceArray)
                }
            } else {
                if (roundName !== "all") {
                    let newRoundArray = state.filter(function (el) {
                        return el.rounds === roundName;
                    });
                    let newPriceArray = newRoundArray.sort(function (a, b) {
                        return a.prices[0].price < b.prices[0].price ? -1 : 1;
                    });
                    if (e.target.value === "lowtohigh") {
                        newPriceArray = newRoundArray.sort(function (a, b) {
                            return a.prices[0].price < b.prices[0].price ? -1 : 1;
                        });
                    } else if (e.target.value === "hightolow") {
                        newPriceArray = newRoundArray.sort(function (a, b) {
                            return a.prices[0].price < b.prices[0].price ? 1 : -1;
                        });
                    }
                    console.log(newPriceArray)
                    setFilterState(newPriceArray)
                } else {
                    let newPriceArray = state.sort(function (a, b) {
                        return a.prices[0].price < b.prices[0].price ? -1 : 1;
                    });
                    if (e.target.value === "lowtohigh") {
                        newPriceArray = state.sort(function (a, b) {
                            return a.prices[0].price < b.prices[0].price ? -1 : 1;
                        });
                    } else if (e.target.value === "hightolow") {
                        newPriceArray = state.sort(function (a, b) {
                            return a.prices[0].price < b.prices[0].price ? 1 : -1;
                        });
                    }
                    console.log(newPriceArray)
                    setFilterState(newPriceArray)
                }
            }
        } else {
            console.log("alllllllll")
            if (hotelName !== "all") {
                let newHotelArray = state.filter(function (el) {
                    return el.hotel[0].name === hotelName;
                });
                if (roundName !== "all") {
                    console.log("alllllllll2")
                    let newRoundArray = newHotelArray.filter(function (el) {
                        return el.rounds === roundName;
                    });
                    setFilterState(newRoundArray)
                } else {
                    console.log("alllllllll3")
                    setFilterState(newHotelArray)
                }
            } else {
                if (roundName !== "all") {
                    console.log("alllllllll4")
                    let newRoundArray = state.filter(function (el) {
                        return el.rounds === roundName;
                    });
                    setFilterState(newRoundArray)
                } else {
                    console.log("alllllllll5")
                    let sorter = initialState.sort(listTopfunc(true))
                    setFilterState(sorter)
                }
            }
        }
    }
    return (<Fade bottom>
        <Products name="golfpaketleri">

            <Baslik><TranslateStatic string="golf_paket_baslik" /></Baslik>
            <Filter
                change={filterRound}
                change2={filterByHotel}
                changeSort={priceSort}
                data={hotellist}
                roundlist={roundlist}
                roundid={roundName}
                hotelname={hotelName}
                sortValue={sortValue}
            />
            {filterstate ? <ItemWrapper>{filterstate.map((x, i) => {
                if (i < gosterimSayisi) {
                    return <Item key={i}>
                        {x.hotel && x.hotel[0] ? <Gallery
                            gallery_id={x.hotel[0].gallery[0] ? x.hotel[0].gallery[0].id : false}
                            hotel_id={x.hotel[0].id} /> : false}
                        <Content>
                            <h4><Link to={`/paket/${x.id}`}>{x.name}</Link></h4>
                            <h3>Single Price: {x.prices && x.prices[0] ? priceSorter(x.prices) : false}</h3>
                            {/* <h3>In Double PP Price: {x.in_double_pp_price} {x.currency === 'USD' ? '$' : x.currency === 'EURO' ? '€' : false}</h3> */}
                            {/* <LocaleContext.Consumer>*/}
                            {x[`${locale}`] ? <Editor
                                toolbarHidden
                                editorState={_editorStateChanger(x, '')}
                                readOnly
                            /> : false}

                        </Content>
                        <ButtonWrapperSC>
                            {/* <Link to={`/paket/${x.Paket_Id}`}>Detay</Link> */}
                            <Link to={`/paket/${x.id}`}><TranslateStatic string="detay" /></Link>
                            <Link to={`/talep/${x.id}`}><TranslateStatic string="talep" /></Link>
                            {/* <button>Talep Et</button> */}
                        </ButtonWrapperSC>
                    </Item>
                } else {
                    return false
                }

            })}</ItemWrapper> : false}
            {filterstate && filterstate.length > 3 ? <ButtonSC onClick={() => setGs(gosterimSayisi + 3)}><TranslateStatic string="more" /></ButtonSC> : false}
        </Products></Fade>
    )
}