import styled from 'styled-components';

export const IcerikSC = styled.div`
display:flex;
background-color:white;
flex-basis:50%;
box-sizing:border-box;
padding:10px;
flex-direction:column;
border-radius:4px;
/* margin-top:10px; */
/* margin-left:10px; */
h3{
    font-weight:700;
    font-size:12pt;
}
ul{
    margin:0;
    padding:0;
    li{
       list-style:none;
       padding:5px;
       word-break:break-word;
       font-size:10.5pt;
       a{
           color:black;
           text-decoration:none;
       }
       svg{
           color:#009900;
           vertical-align: middle;
       }
    }
}
@media screen and (max-width: 1000px) {
   flex-basis:100%;
   margin:10px 0px;
  }
`