import React from 'react';
import { IcerikSC } from './styles'
import { Link } from 'react-router-dom';
import TranslateStatic from './translate'
import { MdKeyboardArrowRight } from "react-icons/md";

export default (props) => {
    function dateChanger2(x) {
        let date2 = new Date(x);
        let result = date2.toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        return result;
    }
    return <IcerikSC>
        <h3><TranslateStatic string="prices" /></h3>
        <h4>
            <span><TranslateStatic string="from2" /></span>
            <span><TranslateStatic string="to" /></span>
            <span><TranslateStatic string="pp" /></span>
            <span><TranslateStatic string="booking_request" /></span>
        </h4>
        <ul>
            {props.data.sort((a, b) => a.start_date > b.start_date ? 1 : a.start_date < b.start_date ? -1 : 0).map((x, i) => {

                return <li>
                    <span>{dateChanger2(x.start_date)}</span>
                    <span>{dateChanger2(x.end_date)}</span>
                    <span>{x.price}{x.currency === 'USD' ? '$' : x.currency === 'EURO' ? '€' : false}</span>
                    <span>
                        <Link to={{ pathname: `/talep/${props.paket_id}` }}>
                            <TranslateStatic string="get_a_quote" />
                            <MdKeyboardArrowRight />
                        </Link>
                    </span>
                </li>
            })}
        </ul>

    </IcerikSC>
}