import styled from 'styled-components'
import DetayImg from '../../assets/detayimg.jpg'
export const IncCourse = styled.div`
/* padding-left:10px; */
 display:flex;
 flex-direction:row;
 flex-basis:100%;
 justify-content:center;
 align-content:flex-start;
 align-items:stretch;
 flex-wrap:wrap;
`
export const Rez = styled.div`
float:right;
box-sizing:border-box;
display:flex;
flex-direction:column;
height:100%;
@media screen and (max-width: 1200px) {
   width:100%;
   margin:10px 0px;
   text-align:center;
  }
a{
    text-decoration:none;
    color:white;
    background-color:#009900;
    padding: 5px 20px;
    border-radius:4px;
    transition:300ms;
    box-sizing:border-box;
    &:hover{
        transition:300ms;
        background:black;
    }
    svg{
        vertical-align:baseline;
        margin-right:5px;
        box-sizing:border-box;
    }
}
`
export const PaketBaslik2= styled.h3`
text-align:center;
margin-top:20px !important;
color: #009900;
`
export const Detail = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
box-sizing:border-box;
flex-basis:100%;
flex-wrap:wrap;
`
export const PageDetailSC = styled.div`
    width:100%;
    /* margin-top:120px; */
    padding:10px 120px 50px;
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    /* background:#f8f4ea; */
    @media screen and (max-width: 1000px) {
    padding:50px;
  }
`;
export const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-basis:100%;
    box-sizing:border-box;
    justify-content:space-between;
    @media screen and ( max-width:1000px ){
        flex-direction:column;
    }
`

export const LeftSC = styled.div`
width:59%;
display:flex;
box-sizing:border-box;
/* background:red; */
padding:10px 10px 10px 10px;
background:white;
flex-direction:column;
border-radius:3px;
@media screen and (max-width: 1000px) {
    width:100%;
  }
h3,h4{
    margin:0;
    padding:0;
    padding-bottom:10px;
    text-transform:capitalize;
}
h4{
    font-family:'Oswald';
    letter-spacing:1px;
    font-weight:400;
    font-size:20px;
    color:#85a093;
    /* font-style:italic; */
}
`
export const RightSC = styled.div`
    border-radius:3px;
    width:39%;
    background:white;
    box-sizing:border-box;
    /* box-sizing:border-box; */
    display:flex;
    flex-direction:column;
    /* background:blue; */
    padding:0px 10px 10px 10px;
    @media screen and ( max-width:1000px ){
        width:100%;
    }
    h3{
        text-align:center;
        padding-bottom: 10px;
        margin:0;
        color:#cca643;
        font-family:'Playfair Display';
        letter-spacing:1px;
        font-weight:600;
        font-size:20px;
        font-style:italic;
    }
    a{
    text-decoration:none;
    width:100%;
    margin:50px auto 10px;
 border:none;
 box-sizing:border-box;
 text-align:center;
 border-radius:3px;
 font-family:'Oswald';
 text-transform:uppercase;
 font-size:16px;
 font-weight:600;
 background-color: #e21e18;
 color:white;
 padding:10px 30px;
 transition:300ms;
 cursor: pointer;
 &:hover{
    transition:300ms;
    background-color:#cca643;
 
 }&:focus{
     border:none;
     outline:none;
 }
}
`
export const PBaslikSC = styled.p`
    width:100%;
    background-color:white;
    color:#353535;
    text-align:center;
    padding:10px;
    box-sizing:border-box;
    font-weight:700;
    border-radius:3px;
    font-size:24px;
    font-family:'Playfair Display';
    font-style:italic;
    margin:0px;
    margin-bottom:10px;
`
export const Dater = styled.div`
    width:100%;
    text-align:center;
    box-sizing:border-box;
    font-size:14px;
    /* font-family:'Oswald' */
`
export const PaketWrapper = styled.div`
width:100%;
background-color:#eff6f3;
border-radius:3px;
float:left;
padding:20px 20px;
box-sizing:border-box;
display:flex;
flex-direction:column;
a{
    text-decoration:none;
    margin:0px auto;
 border:none;
 border-radius:3px;
 font-family:'Oswald';
 text-transform:uppercase;
 font-size:16px;
 font-weight:600;
 background-color:#85a093;
 color:white;
 padding:10px 30px;
 transition:300ms;
 cursor: pointer;
 &:hover{
    transition:300ms;
    background-color:#cca643;
 
 }&:focus{
     border:none;
     outline:none;
 }
}
`
export const Detay = styled.div`
height:100px;
overflow:hidden;
`
export const HotelName = styled.div`
width:100%;
    text-align:center;
    box-sizing:border-box;
    font-size:18px;
`
export const PaketImg = styled.div`
    margin-top:90px;
    float:left;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    display:flex;
    flex-direction:row;
    align-items:center;
width:100%;
height:200px;
background-image:url(${DetayImg});
@media screen and (max-width: 1000px) {
    margin-top:53px;
  }
`
export const PaketBaslik = styled.p`
    flex-basis:100%;
    font-family:'Playfair Display';
    font-size:32px;
    text-align:center;
    font-style:italic;
    color:white;
    font-weight:700;
`
export const ButtonSC = styled.button`
 margin:0px auto;
 border:none;
 border-radius:3px;
 font-family:'Oswald';
 text-transform:uppercase;
 font-size:16px;
 font-weight:600;
 background-color:#85a093;
 color:white;
 padding:10px 30px;
 transition:300ms;
 &:hover{
    transition:300ms;
    background-color:#cca643;
 
 }&:focus{
     border:none;
     outline:none;
 }
`
export const AlinkSC = styled.a`
 margin:0px auto;
 border:none;
 border-radius:3px;
 font-family:'Oswald';
 text-transform:uppercase;
 font-size:16px;
 font-weight:600;
 background-color:#85a093;
 color:white;
 padding:10px 30px;
 transition:300ms;
 cursor: pointer;
 &:hover{
    transition:300ms;
    background-color:#cca643;
 
 }&:focus{
     border:none;
     outline:none;
 }
`