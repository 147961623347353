import axios from "axios";
import { LocaleContext } from '../../store/url'
import authHeader from '../../utils/authHeader'
const API_URL = LocaleContext._currentValue;

export const _paketlistesi = () => {
    return axios.get(API_URL + '/paket', { headers: authHeader() })
}
export const _paketHotellistesi = (id) => {
    return axios.get(API_URL + `/paket/hotel/${id}`, { headers: authHeader() })
}
export const _hotellistesi = () => {
    return axios.get(API_URL + '/hotel', { headers: authHeader() })
}
export const _gallerylistesi = (id) => {
    return axios.get(API_URL + `/image/gallery/${id}`, { headers: authHeader() })
}
export const _hotelDetay = (id) => {
    return axios.get(API_URL + `/hotel/${id}`, { headers: authHeader() })
}
export const _talepSil = (id) => {
    return axios.delete(API_URL + `/talep/${id}`, { headers: authHeader() })
}
export const _post = (data) => {
    return axios.post(API_URL + `/subscription`, { ...data }, { headers: authHeader() })
}
export const _talepGuncelle = (data, id) => {
    return axios.put(API_URL + `/talep/${id}`, { ...data }, { headers: authHeader() })
}